import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Row,
  Input,
  Col,
  InputNumber,
  TimePicker,
  notification,
} from "antd";
import { axiosPost } from "../../helpers/AxiosRequest";
import { UrlConstants } from "../../helpers/UrlConstantsHelper";
import moment from "moment";

const AppModalWithApproveDriverAttendance = (props: any) => {
  const [visible, setVisible] = useState(false);
  const [modalData, setModalData]: any = useState({});
  const [username, setUsername]: any = useState(
    localStorage.getItem("employeename")
  );

  useEffect(() => {
    if (props.selectedRowData.cabid) {
      setModalData(props.selectedRowData);

      let copyselectedValues = props.selectedRowData;
      if (copyselectedValues.closingKM == undefined)
        copyselectedValues.closingKM = 0;
      let temp = copyselectedValues.closingKM.toString().replace(/^0+/, "");
      temp = zeroPad(temp);
      if (temp.length == 7) temp = temp.slice(0, -1);
      copyselectedValues.closingKM = temp;
      copyselectedValues.openingKM = temp;
      setModalData(copyselectedValues);
      setVisible(props.visible);
    }
  }, [props.visible, props.selectedRowData]);
  function zeroPad(num: any) {
    return num.toString().padStart(6, "0");
  }
  const handleOk = (e: any) => {
    props.hideModal(false);
  };

  const handleCancel = (e: any) => {
    props.hideModal(false);
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const onFinish = (values: any) => {};
  const openNotificationWithIcon = (type: any, info: any) => {
    if (type === "Success") {
      notification.success({
        message: info.header,
        description: info.description,
      });
    } else if (type === "Error") {
      notification.error({
        message: info.header,
        description: info.description,
      });
    }
  };

  const OnOpeningKMChange = (e: any) => {
    e = e.target.value.replace(/^0+/, "");
    e = zeroPad(e);
    if (e.length == 7) e = e.slice(0, -1);
    let copyselectedValues = { ...modalData };
    copyselectedValues.openingKM = e;
    setModalData(copyselectedValues);
  };

  const approvelogin = () => {
    const options = {
      data: {
        cabid: modalData.cabid,
        openingKM: modalData.openingKM,
        username: username,
      },
    };

    axiosPost(UrlConstants.approvelogin, options)
      .then((resp: any) => {
        const notificationInfo = {
          header: "Login Request for " + modalData.cabid + " has been Approved",
          description: "",
        };
        openNotificationWithIcon("Success", notificationInfo);
        handleCancel(false);
      })
      .catch(function (error: any) {
        if (error.response) {
          console.log(error.response.data, error.response.status);
        }
      });
  };
  return (
    <Modal
      destroyOnClose={true}
      className="custom-modal-approve-attendance"
      title={props.title}
      width={450}
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[]}
    >
      <Row gutter={[20, 20]}>
        <Col span={6} className="custom-modal-approve-attendance-left-column">
          Cab Id
        </Col>
        <Col span={6}>
          {" "}
          <Input defaultValue={modalData.cabid} readOnly={true} />
        </Col>

        <Col span={6}>Date</Col>
        <Col span={6}>{moment(modalData.dateandtime).format("DD-MM-YYYY")}</Col>
      </Row>
      {/* <Row gutter={[20, 20]}>
        <Col span={12} className="custom-modal-approve-attendance-left-column">
          Date
        </Col>
        <Col span={12}>
          {new Date(Number(modalData.Date)).toLocaleDateString()}
        </Col>
      </Row> */}

      <Row gutter={[20, 20]}>
        <Col span={12} className="custom-modal-approve-attendance-left-column">
          Requested Time :
        </Col>
        <Col span={7}>
          <Input
            defaultValue={moment(modalData.dateandtime).format("h:mm A")}
            readOnly={true}
          />
        </Col>
      </Row>
      <Row gutter={[20, 20]}>
        <Col span={12} className="custom-modal-approve-attendance-left-column">
          Previous Closing Km :
        </Col>
        <Col span={7}>
          <Input
            maxLength={7}
            defaultValue={modalData.closingKM}
            readOnly={true}
          />
        </Col>
      </Row>
      <Row gutter={[20, 20]}>
        <Col span={12} className="custom-modal-approve-attendance-left-column">
          Opening Km :
        </Col>
        <Col span={7}>
          <Input
            maxLength={7}
            defaultValue={modalData.closingKM}
            value={modalData.openingKM}
            onChange={OnOpeningKMChange}
          />
          {/* <InputNumber defaultValue={modalData.closingKM}  maxLength={6}
          value={modalData.closingKM}
            onChange={OnOpeningKMChange}  /> */}
        </Col>
      </Row>
      <Row gutter={[20, 20]}>
        <Col span={12} className="custom-modal-approve-attendance-left-column">
          Time :
        </Col>
        <Col span={7}>
          {" "}
          <Input defaultValue={moment().format("h:mm A")} readOnly={true} />
        </Col>
      </Row>

      <Row gutter={[20, 20]}>
        <Col
          span={12}
          className="custom-modal-approve-attendance-btn-left-column"
        >
          <Button
            className="custom-modal-approve-attendance-approve-btn"
            htmlType="submit"
            onClick={approvelogin}
          >
            Approve
          </Button>
        </Col>
        <Col span={12}>
          <Button
            className="custom-modal-approve-attendance-cancel-btn"
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default AppModalWithApproveDriverAttendance;
