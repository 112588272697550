import React, { useState } from "react";
import { Route } from "react-router-dom";
import Booking from "../pages/Booking/Booking";
import Vehicleamster from "../pages/Master/Vehiclemaster";
import Dashboard from "../pages/Dashboard/Dashboard";
import BookingRoutes from "./BookingRoutes";
import DriverAttendance from "../pages/DriverAttendance/DriverAttndance";
import Feedback from "../pages/Feedback/FeedbackForm";
import VehicleMaster from "../pages/Master/Vehiclemaster";
import LocationMaster from "../pages/Master/Locationmaster";
import CorporateMaster from "../pages/Master/CorporateMaster";
import UserMaster from "../pages/Master/UserMaster";
import GoodsMaster from "../pages/Master/GoodsMaster";
import DayReport from "../pages/Report/DayReport";
import WebReport from "../pages/Report/WebReport";
import StaticsReport from "../pages/Report/StaticsReport";
import CabAtdncReport from "../pages/Report/CabAtdncReport";
import TelecolorReport from "../pages/Report/TeleColorReport";
import DriverDeatils from "../pages/Report/DriverDetails";
import OwnerDetails from "../pages/Report/OwnerDetails";
import VehicleDetails from "../pages/Report/VehicleDetails";
import Vehicleaccount from "../pages/Finance/Vehicleaccounts";
import VehiclePaymentHistory from "../pages/Finance/Vehiclepaymenthistory";
import CorporatePayment from "../pages/Finance/CorporatePaymenthistory";
import CorporateAccount from "../pages/Finance/CorporateAccount";
import TarriffMaster from "../pages/Master/TarriffMaster";
import Attachmentenquiry from "../pages/Attachment/Attachmentenquiry";
//import DriverChanges from "../pages/Report/DriverChanges";
//import UserRolesMaster from "../pages/Master/UserRoles";
//import Sms from "../pages/SMS/Sms";
import CreditReport from "../pages/Credit/CreditReport";
import CreditInvoice from "../pages/Credit/CreditInvoice";
import CreditPaymentHistory from "../pages/Credit/CreditPaymentHistory";
import AppUsers from "../pages/AppUsers";
import PerReport from "../pages/Report/Permissionreport";


const AppRoutes = () => {
  const [Role, setRole]: any = useState(localStorage.getItem("roleid"));
  return (
    <>
    {(Role != 8) && (
      <Route path="/" exact component={Dashboard}></Route>)}
       {(Role == 8) && (
      <Route path="/" exact component={DriverAttendance}></Route>)}
      <Route
        path="/booking"
        render={({ match }: any) => (
          <>
            <Booking>
              <BookingRoutes basePath={match} />
            </Booking>
          </>
        )}
      ></Route>
      <Route
        path="/driverAttendance"
        exact
        component={DriverAttendance}
      ></Route>
      <Route
        path="/feedback"
        exact
        component={Feedback}
      ></Route>
       <Route
        path="/attachmentenquiry"
        exact
        component={Attachmentenquiry}
      ></Route>
       {/* <Route
        path="/sms"
        exact
        component={Sms}
      ></Route> */}
      <Route
        path="/vehiclemaster"
        exact
        component={VehicleMaster}
      ></Route>
      <Route
        path="/locationmaster"
        exact
        component={LocationMaster}
      ></Route>
      <Route
        path="/tarriffmaster"
        exact
        component={TarriffMaster}
      ></Route>
       <Route
        path="/corporatemaster"
        exact
        component={CorporateMaster}
      ></Route>
       <Route
        path="/usermaster"
        exact
        component={UserMaster}
      ></Route>
       <Route
        path="/goodsmaster"
        exact
        component={GoodsMaster}
      ></Route>
      {/* <Route
        path="/userrolesmaster"
        exact
        component={UserRolesMaster}
      ></Route> */}
       <Route
        path="/dayReport"
        exact
        component={DayReport}
      ></Route>
      <Route
        path="/webReport"
        exact
        component={WebReport}
      ></Route>
      <Route
        path="/staticsreport"
        exact
        component={StaticsReport}
      ></Route>
       <Route
        path="/cabatdncreport"
        exact
        component={CabAtdncReport}
      ></Route>
       <Route
        path="/telecolorreport"
        exact
        component={TelecolorReport}
      ></Route>
         <Route
        path="/driverdeatils"
        exact
        component={DriverDeatils}
      ></Route>
      <Route
        path="/ownerdetails"
        exact
        component={OwnerDetails}
      ></Route>
       <Route
        path="/vehicledetails"
        exact
        component={VehicleDetails}
      ></Route>
      {/* <Route
        path="/driverchangesreport"
        exact
        component={DriverChanges}
      ></Route> */}
          <Route
        path="/vehicleaccount"
        exact
        component={Vehicleaccount}
      ></Route>
        <Route
        path="/vehiclepaymenthistory"
        exact
        component={VehiclePaymentHistory}
      ></Route>
        <Route
        path="/corporateaccount"
        exact
        component={CorporateAccount}
      ></Route>
       <Route
        path="/corporatepaymenthistory"
        exact
        component={CorporatePayment}
      ></Route>
       <Route
        path="/creditreport"
        exact
        component={CreditReport}
       ></Route>
        <Route
        path="/creditInvoice"
        exact
        component={CreditInvoice}
       ></Route>
       <Route
        path="/creditpaymenthistory"
        exact
        component={CreditPaymentHistory}
       ></Route>
          <Route
        path="/appusers"
        exact
        component={AppUsers}
       ></Route>
        <Route
        path="/driverpermissions"
        exact
        component={PerReport}
       ></Route>
      
    </>

  );
};

export default AppRoutes;
