import { Col, Row, Spin, DatePicker, Button, Input } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import AppTable from "../../components/AppTable/AppTable";
import axiosGet from "../../helpers/AxiosRequest";
import { UrlConstants } from "../../helpers/UrlConstantsHelper";

const PerReport = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [permList, setpermList] = useState([]);
  const [copypermList, setcopypermList] = useState([]);
  const [fromdate, setfromdate]: any = useState(moment());
  const [todayFlag, setTodayFlag] = useState(false);
  const [prevFlag, setPrevFlag] = useState(false);
  const [todate, settodate]: any = useState(moment());
  const dateFormat = "DD/MM/YYYY";
  const [totalCount, setTotalCount] = useState(0);
  const [SearchText, setsearchText]: any = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const disabledDate = (current: any) => {
    // Can not select days before today and today
    return current && current > moment().endOf("day");
  };
  const disabledFromDate = (current: any) => {
    // Can not select days before today and today
    return current && current > moment(todate).endOf("day");
  };
  const onDateSearch = (
    fromdate: any,
    todate: any,

  ) => {
    DateSearch(
      moment(fromdate).format("YYYY/MM/DD"),
      moment(todate).add("days", 1).format("YYYY/MM/DD"),

    );
  };
  const DateSearch = (
    fromdate: any,
    todate: any,

  ) => {
    setIsLoading(true);
    const options: any = {
      params: {
        fromdate: fromdate,
        todate: todate,

      },
    };
    axiosGet(UrlConstants.getdrPermReport, options)
      .then((resp: any) => {
        resp.data.forEach((e: any) => {
          e.key = e._id.toString();
        });
        setIsLoading(false);
        setpermList(resp.data);
        setcopypermList(resp.data);
        setTotalCount(resp.tablelength);
      })
      .catch(function (error: any) {
        setIsLoading(false);
        if (error.response) {
          console.log(error.response.data, error.response.status);
          setpermList([]);
          setcopypermList([]);
        }
      });
  };
  const onSearchchange = (e: any) => {
    setsearchText(e.target.value);
    globalSearch(e.target.value);
  };
  const globalSearch = (searchText: any) => {
    let filteredData = permList.filter((value: any) => {
      if (value.cabId === undefined || value.cabId === null) value.cabId = "";

      return (
        value.cabId
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) 
      );
    });
   setcopypermList(filteredData);
    setFilteredData(filteredData);
  };
  const columns = [
    {
        title: "Date",
        dataIndex: "permDate",
        key: "Date",
      },
    {
      title: "Cab Id",
      dataIndex: "cabId",
      key: "Cab Id",
    },
    {
        title: "Permission time",
        dataIndex: "permTime",
        key: "pertime",
        ellipsis: true,
        render: (text: any, record: any) => {
          return {
            children: (
                <div>
                  {text ? moment(text).format("hh:mm A") : ""}
                </div>
              ),
          };
        },
      },
  
    {
        title: "Resume time",
        dataIndex: "resumeTime",
        key: "retime",
        ellipsis: true,
        render: (text: any, record: any) => {
          return {
            children: (
                <div>
                  {text ? moment(text).format("hh:mm A") : ""}
                </div>
              ),
          };
        },
      },
      {
        title: "Start time",
        dataIndex: "pStartTime",
        key: "sttime",
        ellipsis: true,
        render: (text: any) => {
          return {
            children: (
              <div>
                {text ? moment(text).format("hh:mm A") : ""}
              </div>
            ),
          };
        },
      },
      {
        title: "Resume By",
        dataIndex: "resumeBy",
        key: "resby",
      },
      {
        title: "Duration",
        key: "duration",
        ellipsis: true,
        render: (_: any, record: any) => {
          if (!record.permTime || !record.resumeTime) return <div></div>;
          const totalMinutes = moment(record.resumeTime).diff(moment(record.permTime), "minutes");
          const hours = Math.floor(totalMinutes / 60);
          const minutes = totalMinutes % 60;
          return <div>{hours ? `${hours}h ` : ""}{minutes ? `${minutes}m` : hours === 0 ? "0m" : ""}</div>;
        },
      },
  ];
  const adayclick = (event: any = null) => {

    var first = moment();
    var firstday = first.format("DD");
    var firstmonth = first.format("MM");
    var firstyear = first.format("YYYY");
    let fromdate = firstyear + "-" + firstmonth + "-" + firstday;
    setfromdate(moment(fromdate));
    //var new_date = moment().add('days', 1);
    var new_date = moment();
    var day = new_date.format("DD");
    var month = new_date.format("MM");
    var year = new_date.format("YYYY");
    let todate = year + "-" + month + "-" + day;
    settodate(moment(todate));
    setTodayFlag(true);
    setPrevFlag(false);
    onDateSearch(fromdate, todate,);
  };


  useEffect(() => {
    // getCustomerList();
    adayclick();
  }, []);
  const onfromdatechange = (value: any) => {
    setfromdate(value);
    try {
      var fromdate = moment(value).format("YYYY/MM/DD");
      let todate = moment().format("YYYY/MM/DD");
      //DateSearch(fromdate, todate);
      setTodayFlag(false);
      setPrevFlag(false);
      //setfromdate(value);
    } catch (ex) {
      console.log(ex);
    }
  };
  const ontodatechange = (value: any) => {
    settodate(value);
    try {
      let startdate = moment(fromdate).format("YYYY/MM/DD");
      //DateSearch(startdate, todate);
      setTodayFlag(false);
      setPrevFlag(false);

      //settodate(moment(todate));
    } catch (ex) {
      console.log(ex);
    }
  };


 
  return (
    <div className="booking-child-routes">
      <div className="booking-child-routes-button-section">

        <Row style={{ paddingBottom: 10 }}>
          <Col>
            <span
              style={{
                fontSize: "large",
                fontWeight: "bold",
                fontStyle: "Times New Roman",
              }}
            >
              PERMISSION REPORT
            </span>
          </Col>
          
        </Row>

        <Row>

          <Col flex="auto" span={8} >
            <span style={{ fontSize: "medium" }}> From:</span>

            <DatePicker
              value={fromdate}
              format={dateFormat}
              disabledDate={disabledFromDate}
              onChange={onfromdatechange}
            />
            <span style={{ fontSize: "medium" }}> To:</span>

            <DatePicker
              //value={todate}
              value={
                todayFlag
                  ? moment()
                  : prevFlag
                    ? moment().add("days", -1)
                    : todate
              }
              format={dateFormat}
              disabledDate={disabledDate}
              onChange={ontodatechange}
            />
           
          </Col>


          <Col span={2} offset={1}>
          <Button
              className="booking-btn-blockSearch"
              onClick={() =>
                onDateSearch(fromdate, todate)
              }
              disabled={moment(fromdate).isAfter(moment(todate))}
            >
              Search
            </Button>
          </Col>
          <Col  span={3} offset={3}>
            <span style={{ fontSize: "large", fontWeight: "bold" }}>
              Count :   {permList.length}
            </span>
          </Col>
          <Col span={3} offset={3}>
          <Input
            placeholder="Search Text"
            className="search"
            onChange={onSearchchange}
          />
        </Col>

        </Row>
        <div>
          <Spin tip="Loading..." spinning={isLoading}>
            <AppTable
              columns={columns}
              data={copypermList}
              enablePagination={true}
              defaultPageSize={100}
              scroll={{ x: "max-content" }}
            ></AppTable>
          </Spin>
        </div>
      </div>
    </div>
  );
};

export default PerReport;
