import React, { useState, useEffect } from "react";
import Modal from "antd/lib/modal/Modal";
import { Form, Input, InputNumber, Button, notification, Select } from "antd";
import axiosGet, { axiosPost } from "../../helpers/AxiosRequest";
import { UrlConstants } from "../../helpers/UrlConstantsHelper";
import { convertToObject } from "typescript";
import TripStatusUpdateModal from "./TripStatusUpdateModal";

const AppModalWithFormEditBooking = (props: any) => {
  const [visible, setVisible] = useState(false);
  const [selectedRowInfo, setSelectedRowInfo]: any = useState({});
  const [username, setUsername]: any = useState(
    localStorage.getItem("employeename")
  );
  const [branchid, setbranchid]: any = useState(localStorage.getItem("cityid"));
  const [roleid, setroleid]: any = useState(localStorage.getItem("roleid"));
  const [locationsList, setLocationsList] = useState([]);
  const [searchFromLocationsList, setSearchFromLocationsList] = useState([]);
  const [selectedFromLocation, setSelectedFromLocation]: any = useState({});
  const [copyinterCityList1, setcopyinterCityList]: any = useState({});
  const [selectedValues, setSelectedValues]: any = useState({});
  const [vehicleTypeList, setVehicleTypeList] = useState([]);
  const [packagesList, setPackagesList] = useState([]);
  const [assignedtime, setassignedtime]: any = useState(new Date());
  const [completetime, setcompletetime]: any = useState(new Date());
  const { Option } = Select;
  // useEffect(() => {
  //   setVisible(props.visible);
  // }, [props.visible]);

  useEffect(() => {
    setVisible(props.visible);
    if (props.selectedRowData) {
      setSelectedRowInfo(props.selectedRowData);
      /* getLocations(branchid);
      getVechileTypeList(branchid);
      if (props.selectedRowData[0].faretype.includes("Intercity Fare"))
        getintercitylist(branchid);
      else if (props.selectedRowData[0].faretype.includes("Package Fare"))
        getPackagesList(branchid, props.selectedRowData[0].vehicletypeid); */

      //onchangekm(props.selectedRowData[0].distance);
    }
  }, [props.selectedRowData, props.visible]);

  const handleOk = (e: any) => {
    props.hideModal(false);
  };

  const handleCancel = (e: any) => {
    props.hideModal(false);
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const onFinish = (values: any) => {};
  const openNotificationWithIcon = (type: any, info: any) => {
    if (type === "Success") {
      notification.success({
        message: info.header,
        description: info.description,
      });
    } else if (type === "Error") {
      notification.error({
        message: info.header,
        description: info.description,
      });
    }
  };

  const onchangekm = (e: any) => {
    /* if (value != '') {
      if (selectedRowInfo.faretype.includes("Intercity Fare"))
        calculateintercityFare(value, selectedRowInfo.vehicletypeid, selectedRowInfo.tripendtime);
      else if (selectedRowInfo.faretype.includes("Meter Fare"))
        calculateMeterFare(value, branchid, selectedRowInfo.vehicletypeid, selectedRowInfo.tripendtime);
      else if (selectedRowInfo.faretype.includes("Package Fare")) {
        calculatepackage(selectedRowInfo.vehicletypeidvalue, value, selectedRowInfo.tripendtime);
      }
    } */
    let copyselectedvalues = { ...selectedRowInfo };
    copyselectedvalues.distance = e;
    setSelectedValues(copyselectedvalues);
    setSelectedRowInfo(copyselectedvalues);
  };

  const getintercitylist = (cityId: any) => {
    axiosGet(UrlConstants.getInterCityList).then((resp: any) => {
      let copyinterCityList: any = resp;
      setcopyinterCityList(resp);
    });
  };

  const getPackagesList = (cityId: any, vehicleid: any) => {
    const uri =
      UrlConstants.getPackagesList +
      "?vehicleid=" +
      vehicleid +
      "&cityid=" +
      cityId;
    axiosGet(uri).then((resp: any) => {
      setPackagesList(resp);
    });
  };

  const getVechileTypeList = (cityId: any) => {
    const options = {
      params: {
        cityid: cityId,
      },
    };
    const uri = UrlConstants.getVehicleTypeList + "?cityid=" + cityId;
    axiosGet(uri).then((resp: any) => {
      setVehicleTypeList(resp);
    });
  };

  const fareChange = (e: any) => {
    let copyselectedvalues = { ...selectedRowInfo };
    copyselectedvalues.fare = e;
    setSelectedValues(copyselectedvalues);
    setSelectedRowInfo(copyselectedvalues);
  };

  const completetrip = () => {
    /*  var diffmin = diff_hours(new Date(props.selectedRowData[0].assignedtime), new Date());
    if (diffmin == selectedRowInfo.tripendtime || diffmin > selectedRowInfo.tripendtime) { */
    const options = {
      data: {
        cabid: props.selectedRowData.cabid,
        bookingid: props.selectedRowData.id,
        distance: selectedRowInfo.distance,
        mins: selectedRowInfo.duration,
        fare: selectedRowInfo.fare,
        updatedby: username,
      },
    };
    axiosPost(UrlConstants.editcompletedtrip, options)
      .then((resp: any) => {
        const notificationInfo = {
          header: "Completed Trip Edited Successfully",
          description: "",
        };
        openNotificationWithIcon("Success", notificationInfo);
        handleCancel(false);
      })
      .catch(function (error: any) {
        if (error.response) {
          console.log(error.response.data, error.response.status);
        } else {
          const notificationInfo = {
            header: "Completed Trip Edit Failed",
            description: "Error while editing the Completed Trip",
          };
          openNotificationWithIcon("Error", notificationInfo);
        }
      });
  };
  const continueTrip = () => {
    const options = {
      data: {
        cabid: props.selectedRowData.cabid,
        tripid: props.selectedRowData.id,
        co_updatedby: username,
      },
    };
    axiosPost(UrlConstants.continueTrip, options)
      .then((resp: any) => {
        const notificationInfo = {
          header: `Continue Trip id: ${props.selectedRowData.id} updated`,
          description: "",
        };
        openNotificationWithIcon("Success", notificationInfo);
        handleCancel(false);
      })
      .catch(function (error: any) {
        if (error.response) {
          console.log(error.response.data, error.response.status);
        } else {
          const notificationInfo = {
            header: "Continue Trip Failed",
            description: "Error while continuing Trip",
          };
          openNotificationWithIcon("Error", notificationInfo);
        }
      });
  };

  const onchangemin = (e: any) => {
    let copyselectedvalues = { ...selectedRowInfo };
    copyselectedvalues.duration = e;
    setSelectedValues(copyselectedvalues);
    setSelectedRowInfo(copyselectedvalues);
  };

  const onFromLocationSearch = (value: any) => {
    if (value) {
      // const searchedList = locationsList.filter((x:any) => {
      //   x.PlaceName.startsWith(value)
      // });
      var filtered = locationsList.filter((x: any) =>
        String(x.PlaceName.toLowerCase()).startsWith(value.toLowerCase())
      );
      setSearchFromLocationsList(filtered);
    } else {
      setSearchFromLocationsList([]);
    }
  };
  const getLocations = (value: any) => {
    const options = {
      params: {
        cityid: value,
      },
    };
    axiosGet(UrlConstants.getLocations, options).then((resp: any) => {
      setLocationsList(resp);
    });
  };
  /*  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not validate email!",
      number: "${label} is not a validate number!",
    },

  }; */
  /*  const feedbackchange = (e: any) => {
    let obj = { ...selectedRowInfo };
    obj.feedback = e.target.value
    setSelectedRowInfo(obj);
  };
 */

  return (
    <Modal
      destroyOnClose={true}
      className="custom-modal-form"
      title={props.title}
      width={420}
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[]}
    >
      {/* <>{selectedRowInfo.id}</> */}
      <Form
        {...layout}
        name="nest-messages"
        onFinish={onFinish}
        //initialValues={{ ["km"]: selectedRowInfo.distance }}
        //validateMessages={validateMessages}
      >
        <Form.Item name={["user", "bookingId"]} label="Booking Id" rules={[]}>
          <Input defaultValue={selectedRowInfo.id} readOnly={true} />
        </Form.Item>
        <Form.Item name={["user", "cabId"]} label="Cab Id" rules={[]}>
          <Input defaultValue={selectedRowInfo.cabid} readOnly={true} />
        </Form.Item>
        <Form.Item
          name={"km"}
          label="Km"
          //rules={[{ type: "number", required: true }]}
        >
          <InputNumber
            onChange={onchangekm}
            value={selectedRowInfo.distance}
            defaultValue={selectedRowInfo.distance}
          />
        </Form.Item>
        <Form.Item
          name={["user", "time"]}
          label="Time"
          // rules={[{ required: true }]}
        >
          <span style={{ display: "none" }}>{selectedRowInfo.duration}</span>

          <InputNumber
            defaultValue={selectedRowInfo.duration}
            //value={selectedRowInfo.duration}
            onChange={onchangemin}
          />
        </Form.Item>

        <Form.Item
          name={["user", "fare"]}
          label="Fare"
          //rules={[{ required: true }]}
        >
          <span style={{ display: "none" }}>{selectedRowInfo.fare}</span>
          <InputNumber
            value={selectedRowInfo.fare}
            defaultValue={selectedRowInfo.fare}
            onChange={fareChange}
          />
        </Form.Item>
        {/* <Form.Item
          label="Location"
        >
          <span style={{ display: "none" }}>
            {selectedValues.fromlocName}
          </span>
          <Select
            showSearch
            optionFilterProp="children"
            onChange={onFromLocationChange}
            defaultValue={selectedRowInfo.toloc}
            value={selectedValues.fromlocName}
            filterOption={(input: any, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >=
              0
            }
            showArrow={false}
            onSearch={onFromLocationSearch}
            placeholder="Search From Location"
            notFoundContent={null}

          >
            {searchFromLocationsList.map((item: any) => (
              <Option key={item._id} value={item.ID} others={item}>
                {item.PlaceName}
              </Option>
            ))}
          </Select>

        </Form.Item>
        <Form.Item name={["user", "feedback"]} label="Feedback" >
          <Input value={selectedRowInfo.feedback} onChange={feedbackchange} />
        </Form.Item> */}
        <Form.Item
          wrapperCol={{ span: 20, offset: 4 }}
          style={{ textAlign: "center" }}
        >
          {(roleid == 1 || roleid == 2) && (
            <Button
              className="custom-modal-form-submit-btn"
              htmlType="submit"
              onClick={completetrip}
              style={{ marginRight: "10px" }}
            >
              Update Trip
            </Button>
          )}

          <Button
            className="booking-btn-update"
            htmlType="submit"
            onClick={continueTrip}
          >
            Continue Trip
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AppModalWithFormEditBooking;
