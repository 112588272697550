import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Select,
  InputNumber,
  notification,
  Input,
  DatePicker,
  TimePicker,
  Button,
  Checkbox,
  Modal,
  Spin,
} from "antd";
import moment from "moment";
import "./AddBooking.scss";
import AppTable from "../../../components/AppTable/AppTable";
import axiosGet, { axiosPost } from "../../../helpers/AxiosRequest";
import { UrlConstants } from "../../../helpers/UrlConstantsHelper";
import { useHistory } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import AppModalWithGrid from "../../../components/AppModal/AppModalWithGrid";
import { Console } from "console";
const { Option } = Select;

const AddBooking = () => {
  let history: any = useHistory();
  const [branchList, setBranchList] = useState([]);
  const [gridModelVisible, setGridModalVisible] = useState(false);
  const [availableCabData, setAvailableCabData] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [companySearchedList, setCompanySearchedList] = useState([]);
  const [goodsList, setGoodsList] = useState([]);
  const [vehicleTypeList, setVehicleTypeList] = useState([]);
  const [tripTypeList, setTripTypeList]: any = useState([]);
  const [packagesList, setPackagesList] = useState([]);
  const [NightFareList, setNightFareList] = useState([]);
  const [interCityList, setInterCityList] = useState([]);
  const [interCityReturnList, setInterCityReturnList] = useState([]);
  const [availableCabList, setAvailableList]: any = useState([]);
  const [bookingHistoryList, setHistoryList] = useState([]);
  const [locationsList, setLocationsList] = useState([]);
  const [searchFromLocationsList, setSearchFromLocationsList] = useState([]);
  const [searchToLocationsList, setSearchToLocationsList] = useState([]);

  const [customerName, setCustomerName] = useState("");
  const [selectedFromLocation, setSelectedFromLocation]: any = useState({});
  const [selectedToLocation, setSelectedToLocation]: any = useState({});
  // const [defaultCompanyName, setDefaultCompanyName] = useState("");
  const [defaultDateTime, setDefaultDateTime] = useState(moment());
  const [is30Checked, setIs30Checked] = useState(false);
  const [cvChecked, setCvChecked] = useState(false);
  const [tollGateChecked, setTollGateChecked] = useState(false);
  const [gstChecked, setGSTChecked] = useState(false);
  const [roundtripChecked, setroundtripChecked] = useState(false);
  const [creditChecked, setCreditChecked] = useState(false);
  const [noOfBookings, setNoOfBookings] = useState(1);

  const [isEditBooking, setIsEditBooking] = useState(false);
  const [disableFields, setDisableFields] = useState(false);

  const [selectedValues, setSelectedValues]: any = useState({});
  const [calculatedMeterFareInfo, setCalculatedMeterFareInfo]: any = useState(
    {}
  );
  const [branchid, setbranchid]: any = useState(localStorage.getItem("cityid"));
  const [username, setUsername]: any = useState(
    localStorage.getItem("employeename")
  );

  const [showValidations, setValidations] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const dateFormat = "DD/MM/YYYY";
  const [mobileLengthVal, setMobileLengthVal] = useState("");

  let tempavialablecabs: any = [];
  const onFinish = (values: any) => {};

  useEffect(() => {
    const isEdit =
      history.location &&
      history.location.pathname === "/booking/editBooking" &&
      history.location.state &&
      history.location.state.details;
    setIsEditBooking(isEdit);
    if (isEdit) {
      const values = history.location.state.details;
      history.location?.state?.isCurrentBooking && setDisableFields(true);
      getEditBookingDetails(values);
      onGoodsList();
      // onAvailableCabsList();
    } else {
      getBranchDetails();
      onGoodsList();
      // onAvailableCabsList();
    }
    checkTimeAndEnableAdditionalFare(defaultDateTime);
  }, []);

  useEffect(() => {
    if (isEditBooking) {
      const selectedFromLoc = locationsList.filter(
        (x: any) => x.ID === selectedValues.fromloc
      )[0];
      setSelectedFromLocation(selectedFromLoc);
    }
  }, [locationsList, selectedValues.fromloc]);

  const getEditBookingDetails = (values: any) => {
    const uri = UrlConstants.getEditBookingDetails + "?bookingid=" + values.id;
    axiosGet(uri).then((resp: any) => {
      const bookingDetails = resp[0];
      setCustomerName(values.name);
      let selectedValuesCopy = { ...selectedValues };
      selectedValuesCopy.bookingId = values.id;
      selectedValuesCopy.goodsType = bookingDetails.goodsid;
      selectedValuesCopy.fromloc = bookingDetails.fromlocid;
      selectedValuesCopy.toloc = bookingDetails.tolocid;
      selectedValuesCopy.vehicleType = bookingDetails.vehicleid;
      selectedValuesCopy.tripType = bookingDetails.triptype;
      selectedValuesCopy.intercitytype = bookingDetails.intercitytype;
      selectedValuesCopy.company = bookingDetails.companyid;
      selectedValuesCopy.packagetype = bookingDetails.packagetype;
      selectedValuesCopy.date = moment(values.bookedtime);
      selectedValuesCopy.time = moment(values.bookedtime);
      selectedValuesCopy.fromlocName = bookingDetails.fromloc;
      selectedValuesCopy.tolocName = bookingDetails.toloc;
      selectedValuesCopy.goodsTypeName = values.goods;
      selectedValuesCopy.mobileNo = values.mobileno;
      selectedValuesCopy.alternativeNo = values.alternativemobileno;
      selectedValuesCopy.refferedby = values.refferedby;
      selectedValuesCopy.city = bookingDetails.branchid;
      selectedValuesCopy.distance = bookingDetails.distance;
      selectedValuesCopy.remarks = values.remarks;
      setSelectedValues(selectedValuesCopy);
      setGSTChecked(values.isgst);
      setTollGateChecked(values.istollgate);
      setCvChecked(values.iscv);
      //   setIs30Checked(values.isadditionalfare);
      setCreditChecked(values.iscredit);
      getBranchDetails(bookingDetails.branchid);
      getPackageAndInterCityList(
        bookingDetails.branchid,
        bookingDetails.vehicleid
      );
      calculateMeterFare(
        bookingDetails.distance,
        bookingDetails.branchid,
        bookingDetails.vehicleid
      );

      getTripTypeList(true);
    });
  };

  const getBranchDetails = (id: any = null) => {
    setIsLoading(true);
    axiosGet(UrlConstants.getBranchList)
      .then((resp: any) => {
        setBranchList(resp);

        const defaultBranchList = resp.filter(
          (x: any) => x.id === id || "CBE001"
        );
        if (defaultBranchList.length === 0) {
          return;
        }
        onBranchChange(defaultBranchList[0].id, null, id ? true : false);
      })
      .catch(function (error: any) {
        if (error.response) {
          console.log(error.response.data, error.response.status);
        }
      });
  };

  const onGoodsList = () => {
    axiosGet(UrlConstants.getGoodsList)
      .then((resp: any) => {
        setGoodsList(resp);
      })
      .catch(function (error: any) {
        if (error.response) {
          //console.log(error.response.data, error.response.status);
        }
      });
  };

  // const onAvailableCabsList = (vehicletypeid: any, fromloc: any = null) => {
  //   const options = {
  //     params: {
  //       branchid: branchid,
  //       vehicletype: vehicletypeid,
  //     },
  //   };
  //   setAvailableList([]);
  //   axiosGet(UrlConstants.getAvailableCabs, options).then((resp: any) => {
  //     var MainInfo: any = [];
  //   //  setAvailableList(resp);
  //     setAvailableCabData(resp);
  //     //console.log(availableCabList);
  //     resp.forEach((item: any) => {
  //       let uri = UrlConstants.getDistance + "?fromlat=" + selectedValues.fromloclat + "&fromlong=" +
  //         selectedValues.fromloclong + "&tolat=" + item.latitude + "&tolong=" + item.longitude;
  // if (fromloc != null) {
  //   uri = UrlConstants.getDistance + "?fromlat=" + fromloc.Latitude + "&fromlong=" +
  //     fromloc.Longitude + "&tolat=" + item.latitude + "&tolong=" + item.longitude;
  // }
  //       axiosGet(uri)
  //         .then((res: any) => {
  //           item.distance = Number(res[0].distance.replace(/[^\d.-]/g, ''));
  //           MainInfo.push(item);
  //           //console.log(MainInfo.length);
  //           MainInfo.sort((a: any, b: any) => {
  //             return a.distance > b.distance ? 1 : a.distance < b.distance ? -1 : a.tripfare > b.tripfare ? 1 : a.tripfare < b.tripfare ? -1 : a.totaltrip > b.totaltrip ? 1 : a.totaltrip < b.totaltrip ? -1 : 0
  //           })
  //           if (MainInfo.length === resp.length) {
  //             let mainlist=[];
  //             for(let index = 0; index < 3; index++) {
  //               mainlist.push(MainInfo[index]);
  //             }
  //             setAvailableList(mainlist);
  //             setAvailableCabData(MainInfo);
  //           }
  //         });
  //     })
  //   }).catch(function (error: any) {
  //     if (error.response) {
  //       //console.log(error.response.data, error.response.status);
  //       setAvailableList([]);
  //     }
  //   });
  // };
  const groupBy = (items: any, key: any) =>
    items.reduce(
      (result: any, item: any) => ({
        ...result,
        [item[key]]: [...(result[item[key]] || []), item],
      }),
      {}
    );
  const onAvailableCabsList = (vehicletypeid: any, fromloc: any = null) => {
    if (
      (selectedValues.fromloclat || fromloc != null) &&
      (selectedValues.fromloclong || fromloc != null)
    ) {
      const options = {
        params: {
          branchid: branchid,
          vehicletype: vehicletypeid,
        },
      };
      axiosGet(UrlConstants.getAvailableCabs, options).then((resp: any) => {
        var MainInfo: any = [];
        var TataAce: any = [];
        var Bolero: any = [];
        var FNS: any = [];
        var T: any = [];
        var B: any = [];
        var F: any = [];
        try {
          var cabslist = groupBy(resp.data, "cabtype");
        } catch {}

        let TataacePromise = new Promise(function (myResolve, myReject) {
          try {
            if (cabslist.TataAce) {
              const copyTataAce = cabslist.TataAce.slice(0);
              //setAvailableCabData(cabslist.TataAce);
              cabslist.TataAce.forEach((item: any) => {
                item.key = item.vehicletypeid;
                var obj: any = {};
                if (item.latitude) {
                  let uri =
                    UrlConstants.getDistance +
                    "?fromlat=" +
                    selectedValues.fromloclat +
                    "&fromlong=" +
                    selectedValues.fromloclong +
                    "&tolat=" +
                    item.latitude +
                    "&tolong=" +
                    item.longitude;
                  if (fromloc != null) {
                    uri =
                      UrlConstants.getDistance +
                      "?fromlat=" +
                      fromloc.Latitude +
                      "&fromlong=" +
                      fromloc.Longitude +
                      "&tolat=" +
                      item.latitude +
                      "&tolong=" +
                      item.longitude;
                    // item.distance=0;
                  }
                  axiosGet(uri).then((res: any) => {
                    item.distance = Math.round(
                      Number(res[0].distance.replace(/[^\d.-]/g, ""))
                    );
                    TataAce.push(item);
                    TataAce.sort((a: any, b: any) => {
                      return a.distance > b.distance
                        ? 1
                        : a.distance < b.distance
                        ? -1
                        : a.tripfare > b.tripfare
                        ? 1
                        : a.tripfare < b.tripfare
                        ? -1
                        : a.totaltrip > b.totaltrip
                        ? 1
                        : a.totaltrip < b.totaltrip
                        ? -1
                        : 0;
                    });
                    //   MainInfo.sort(function(a:any, b:any) {
                    //     return a.distance - b.distance;
                    // });
                    // if (!cabslist.Bolero) {
                    if (TataAce.length === copyTataAce.length) {
                      myResolve(TataAce);
                    }
                    // }
                  });
                } else {
                  const index = copyTataAce.indexOf(item);

                  if (index > -1) {
                    copyTataAce.splice(index, 1);
                  }
                  if (copyTataAce.length == 0) myResolve(TataAce);
                }
              });
            } else myResolve(TataAce); // when error
          } catch (error) {
            myReject(error);
          }
        });
        TataacePromise.then(
          function (value) {
            T = value;
            fetchavailablecabs(value, "Tataace", vehicletypeid);
          },
          function (error) {
            console.log(error);
          }
        );

        let BoleroPromise = new Promise(function (myResolve, myReject) {
          try {
            if (cabslist.Bolero) {
              const copybolero = cabslist.Bolero.slice(0);
              cabslist.Bolero.forEach((item: any) => {
                item.key = item.vehicletypeid;
                var obj: any = {};
                if (item.latitude) {
                  let uri =
                    UrlConstants.getDistance +
                    "?fromlat=" +
                    selectedValues.fromloclat +
                    "&fromlong=" +
                    selectedValues.fromloclong +
                    "&tolat=" +
                    item.latitude +
                    "&tolong=" +
                    item.longitude;
                  if (fromloc != null) {
                    uri =
                      UrlConstants.getDistance +
                      "?fromlat=" +
                      fromloc.Latitude +
                      "&fromlong=" +
                      fromloc.Longitude +
                      "&tolat=" +
                      item.latitude +
                      "&tolong=" +
                      item.longitude;
                  }
                  axiosGet(uri)
                    .then((res: any) => {
                      item.distance = Math.round(
                        Number(res[0].distance.replace(/[^\d.-]/g, ""))
                      );
                      Bolero.push(item);
                      Bolero.sort((a: any, b: any) => {
                        return a.distance > b.distance
                          ? 1
                          : a.distance < b.distance
                          ? -1
                          : a.tripfare > b.tripfare
                          ? 1
                          : a.tripfare < b.tripfare
                          ? -1
                          : a.totaltrip > b.totaltrip
                          ? 1
                          : a.totaltrip < b.totaltrip
                          ? -1
                          : 0;
                      });
                      if (Bolero.length === copybolero.length) {
                        myResolve(Bolero);
                      }
                      
                    })
                    .catch((error: any) => {
                      console.log(error)
                    });
                } else {
                  const index = copybolero.indexOf(item);
                  if (index > -1) {
                    copybolero.splice(index, 1);
                  }
                  if (copybolero.length == 0) myResolve(Bolero);
                }
              });
            } else myResolve(Bolero); // when error
          } catch (error) {
            myReject(error);
          }
        });
        BoleroPromise.then(
          function (value) {
            B = value;
            fetchavailablecabs(value, "Bolero", vehicletypeid);
          },
          function (error) {
            console.log(error);
          }
        );

        let FNSPromise = new Promise(function (myResolve, myReject) {
          try {
            if (cabslist["407"]) {
              const copyFNS = cabslist["407"].slice(0);
              cabslist["407"].forEach((item: any) => {
                item.key = item.vehicletypeid;
                var obj: any = {};
                if (item.latitude) {
                  let uri =
                    UrlConstants.getDistance +
                    "?fromlat=" +
                    selectedValues.fromloclat +
                    "&fromlong=" +
                    selectedValues.fromloclong +
                    "&tolat=" +
                    item.latitude +
                    "&tolong=" +
                    item.longitude;
                  if (fromloc != null) {
                    uri =
                      UrlConstants.getDistance +
                      "?fromlat=" +
                      fromloc.Latitude +
                      "&fromlong=" +
                      fromloc.Longitude +
                      "&tolat=" +
                      item.latitude +
                      "&tolong=" +
                      item.longitude;
                    item.distance = 0;
                  }
                  axiosGet(uri).then((res: any) => {
                    item.distance = Math.round(
                      Number(res[0].distance.replace(/[^\d.-]/g, ""))
                    );
                    FNS.push(item);
                    FNS.sort((a: any, b: any) => {
                      return a.distance > b.distance
                        ? 1
                        : a.distance < b.distance
                        ? -1
                        : a.tripfare > b.tripfare
                        ? 1
                        : a.tripfare < b.tripfare
                        ? -1
                        : a.totaltrip > b.totaltrip
                        ? 1
                        : a.totaltrip < b.totaltrip
                        ? -1
                        : 0;
                    });
                    
                    if (FNS.length === copyFNS.length) {
                      myResolve(FNS);
                    }
                   
                  });
                } else {
                  const index = copyFNS.indexOf(item);
                  if (index > -1) {
                    copyFNS.splice(index, 1);
                  }
                  if (copyFNS.length == 0) myResolve(FNS);
                }
              });
              // myResolve(FNS);
            } else myResolve(FNS); // when error
          } catch (error) {
            myReject(error);
          }
        });
        FNSPromise.then(
          function (value) {
            F = value;
              fetchavailablecabs(value, "Fns", vehicletypeid);
          },
          function (error) {
            console.log(error);
          }
        );
      });
    }
  };

  const fetchavailablecabs = (cabslist: any, name: any, vehicleid: any) => {
    tempavialablecabs[name] = cabslist;
    if (
      tempavialablecabs.Tataace &&
      tempavialablecabs.Bolero &&
      tempavialablecabs.Fns
    ) {
      var MainInfo = [];
      if (vehicleid == 1) {
        MainInfo = tempavialablecabs.Tataace.concat(tempavialablecabs.Bolero);
        MainInfo = MainInfo.concat(tempavialablecabs.Fns);

        let mainlist = [];
        for (let index = 0; index < 3; index++) {
          mainlist.push(MainInfo[index]);
        }
        setAvailableList(mainlist);
        setAvailableCabData(MainInfo);
      } else if (vehicleid == 2) {
        MainInfo = tempavialablecabs.Bolero.concat(tempavialablecabs.Tataace);
        MainInfo = MainInfo.concat(tempavialablecabs.Fns);
        let mainlist = [];
        for (let index = 0; index < 3; index++) {
          mainlist.push(MainInfo[index]);
        }
        setAvailableList(mainlist);
        setAvailableCabData(MainInfo);
      } else if (vehicleid == 3) {
        MainInfo = tempavialablecabs.Fns.concat(tempavialablecabs.Bolero);
        MainInfo = MainInfo.concat(tempavialablecabs.Tataace);
        let mainlist = [];
        for (let index = 0; index < 3; index++) {
          mainlist.push(MainInfo[index]);
        }
        setAvailableList(mainlist);
        setAvailableCabData(MainInfo);
      }
    }
  };

  const calculatedistancedynamic = async (uri: any) => {
    axiosGet(uri).then((res: any) => {
      return Number(res[0].distance.replace(/[^\d.-]/g, ""));
    });
  };

  function compare(a: any, b: any) {
    var bandA = a.distance;
    var bandB = b.distance;
    let comparison = 0;
    if (bandA > bandB) {
      comparison = -1;
    } else if (bandA < bandB) {
      comparison = 1;
    }
    return comparison;
  }
  function isNumber(n: any) {
    return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
  }

  const getVechileTypeList = (cityId: any, isEditScreen: false) => {
    const options = {
      params: {
        cityid: cityId,
      },
    };
    const uri = UrlConstants.getVehicleTypeList + "?cityid=" + cityId;
    axiosGet(uri).then((resp: any) => {
      setVehicleTypeList(resp);
      let vehicleindex = resp.findIndex((obj: { id: any }) => obj.id === 1);
      resp[vehicleindex].city = cityId;
      if (!isEditScreen) onVehicleTypeChange(resp[vehicleindex], null);
    });
  };

  const getTripTypeList = (
    isEditBooking: boolean = false,
    vehicleId: any = null,
    otherInfo: any = null
  ) => {
    axiosGet(UrlConstants.getTripTypeList).then((resp: any) => {
      setTripTypeList(resp);
      if (isEditBooking) {
        setIsLoading(false);
        return;
      }

      let selectedValuesCopy = { ...selectedValues };
      getPackageAndInterCityList(selectedValues.city, vehicleId);
      if (!selectedValuesCopy.tripType) {
        let distance = 0;
        distance =
          selectedValues.distance &&
          selectedValues.distance.replace(/[^\d.-]/g, "");
        const tripType =
          Number(distance) > 21 ? "Intercity Fare" : "Meter Fare";
        if (tripType == "Intercity Fare") {
          if (roundtripChecked) {
            let distance1 = Number(distance) * 2;
            let distancestring = distance1.toString() + " KM";
            calculateintercityReturnFare(distancestring);
          } else {
            let dis = distance + " KM";
            calculateintercityFare(dis, vehicleId, otherInfo?.loadcapacity);
          }
        }
        const defaultValue = resp.filter((x: any) => x.name === tripType);
        if (defaultValue.length === 0) {
          return;
        }

        selectedValuesCopy.tripType = defaultValue[0].id;
      }
      selectedValuesCopy.vehicleType = vehicleId;
      selectedValuesCopy.vehicleTypeLoadCapicity = otherInfo?.loadcapacity;
      setSelectedValues(selectedValuesCopy);
      setIsLoading(false);
    });
  };
  const onTripTypeChange = (value: any, fullInfo: any = null) => {
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.tripType = value;
    setSelectedValues(selectedValuesCopy);
    getPackageAndInterCityList(selectedValues.city, selectedValues.vehicleType);
    if (value === "1")
      calculateMeterFare(
        selectedValues.distance,
        selectedValues.city,
        selectedValues.vehicleType
      );
    if (value === "3") {
      if (roundtripChecked) {
        let distance =
          Number(selectedValues.distance.replace(/[^\d.-]/g, "")) * 2;
        let distancestring = distance.toString() + " KM";
        calculateintercityReturnFare(distancestring);
      } else calculateintercityFare(selectedValues.distance);
    }
    if (value == "2") {
      if (selectedValuesCopy.packagetype != undefined)
        onPackageChange(selectedValuesCopy.packagetype);
    }
  };

  const calculateintercityFare = async (
    distance: any,
    vehiycleType = selectedValues.vehiycleType,
    vehicleTypeLoadCapicity = selectedValues.vehicleTypeLoadCapicity
  ) => {
    if (distance != undefined) {
      if (interCityList.length == 0) {
        axiosGet(UrlConstants.getInterCityList).then((resp: any) => {
          let copyinterCityList: any = resp;
          distance = distance.replace(/[^\d.-]/g, "");
          for (let i = 0; i < copyinterCityList.length; i++) {
            let a = i + 1;
            if (copyinterCityList.length > a) {
              if (
                Number(distance) >= copyinterCityList[i].tollerenckm &&
                Number(distance) < copyinterCityList[a].tollerenckm
              ) {
                try {
                  let selectedValuesCopy = { ...selectedValues };
                  selectedValuesCopy.intercitytype =
                    copyinterCityList[i].basekm;
                  selectedValuesCopy.intercity =
                    copyinterCityList[i].basekm +
                    "-" +
                    copyinterCityList[i].basefare +
                    "-" +
                    Math.floor(copyinterCityList[i].baseminute / 60) +
                    "." +
                    (copyinterCityList[i].baseminute % 60) +
                    " Hrs";
                  selectedValuesCopy.tripType = "3";
                  selectedValuesCopy.vehicleType = vehiycleType;
                  selectedValuesCopy.vehicleTypeLoadCapicity =
                    vehicleTypeLoadCapicity;
                  setSelectedValues(selectedValuesCopy);
                } catch (ex) {
                  console.log(ex);
                }

                let vehicleindex = vehicleTypeList.findIndex(
                  (obj: { id: any }) => obj.id === vehiycleType
                );
                let vechicletype: any = vehicleTypeList[vehicleindex];
                if (vechicletype != undefined) {
                  copyinterCityList[i].addkmcharge = vechicletype.addkmcharge;
                  copyinterCityList[i].addminutecharge =
                    vechicletype.addminutecharge;
                  copyinterCityList[i].approximatefare =
                    copyinterCityList[i].basefare;
                  copyinterCityList[i].tempapproximatefare =
                    copyinterCityList[i].basefare;
                  calculateinterFare(
                    distance,
                    copyinterCityList[i].basekm,
                    copyinterCityList[i].basefare,
                    vechicletype.addkmcharge,
                    copyinterCityList[i]
                  );
                }
                //setCalculatedMeterFareInfo(copyinterCityList[i]);
                return copyinterCityList[i];
              }
            } else if (copyinterCityList.length == a) {
              if (Number(distance) >= copyinterCityList[i].tollerenckm) {
                try {
                  let selectedValuesCopy = { ...selectedValues };
                  selectedValuesCopy.intercitytype =
                    copyinterCityList[i].basekm;
                  selectedValuesCopy.intercity =
                    copyinterCityList[i].basekm +
                    "-" +
                    copyinterCityList[i].basefare +
                    "-" +
                    Math.floor(copyinterCityList[i].baseminute / 60) +
                    "." +
                    (copyinterCityList[i].baseminute % 60) +
                    " Hrs";
                  selectedValuesCopy.tripType = "3";
                  setSelectedValues(selectedValuesCopy);
                } catch (ex) {
                  console.log(ex);
                }
                let vehicleindex = vehicleTypeList.findIndex(
                  (obj: { id: any }) => obj.id === selectedValues.vehicleType
                );
                let vechicletype: any = vehicleTypeList[vehicleindex];
                copyinterCityList[i].addkmcharge = vechicletype.addkmcharge;
                copyinterCityList[i].addminutecharge =
                  vechicletype.addminutecharge;
                copyinterCityList[i].approximatefare =
                  copyinterCityList[i].basefare;
                copyinterCityList[i].tempapproximatefare =
                  copyinterCityList[i].basefare;
                calculateinterFare(
                  distance,
                  copyinterCityList[i].basekm,
                  copyinterCityList[i].basefare,
                  vechicletype.addkmcharge,
                  copyinterCityList[i]
                );
                //setCalculatedMeterFareInfo(copyinterCityList[i]);
                return copyinterCityList[i];
              }
            }
          }
        });
      } else {
        let copyinterCityList: any = interCityList;
        distance = distance.replace(/[^\d.-]/g, "");
        for (let i = 0; i < copyinterCityList.length; i++) {
          let a = i + 1;
          if (copyinterCityList.length > a) {
            if (
              Number(distance) >= copyinterCityList[i].tollerenckm &&
              Number(distance) < copyinterCityList[a].tollerenckm
            ) {
              try {
                let selectedValuesCopy = { ...selectedValues };
                selectedValuesCopy.distance = distance + " KM";
                selectedValuesCopy.intercitytype = copyinterCityList[i].basekm;
                selectedValuesCopy.intercity =
                  copyinterCityList[i].basekm +
                  "-" +
                  copyinterCityList[i].basefare +
                  "-" +
                  Math.floor(copyinterCityList[i].baseminute / 60) +
                  "." +
                  (copyinterCityList[i].baseminute % 60) +
                  " Hrs";
                selectedValuesCopy.tripType = "3";
                setSelectedValues(selectedValuesCopy);
              } catch (ex) {
                console.log(ex);
              }
              let vehicleindex = vehicleTypeList.findIndex(
                (obj: { id: any }) => obj.id === selectedValues.vehicleType
              );
              let vechicletype: any = vehicleTypeList[vehicleindex];
              copyinterCityList[i].addkmcharge = vechicletype.addkmcharge;
              copyinterCityList[i].addminutecharge =
                vechicletype.addminutecharge;
              copyinterCityList[i].approximatefare =
                copyinterCityList[i].basefare;
              copyinterCityList[i].tempapproximatefare =
                copyinterCityList[i].basefare;
              calculateinterFare(
                distance,
                copyinterCityList[i].basekm,
                copyinterCityList[i].basefare,
                vechicletype.addkmcharge,
                copyinterCityList[i]
              );
              return copyinterCityList[i];
            }
          } else if (copyinterCityList.length == a) {
            if (Number(distance) >= copyinterCityList[i].tollerenckm) {
              try {
                let selectedValuesCopy = { ...selectedValues };
                selectedValuesCopy.intercitytype = copyinterCityList[i].basekm;
                selectedValuesCopy.intercity =
                  copyinterCityList[i].basekm +
                  "-" +
                  copyinterCityList[i].basefare +
                  "-" +
                  Math.floor(copyinterCityList[i].baseminute / 60) +
                  "." +
                  (copyinterCityList[i].baseminute % 60) +
                  " Hrs";
                selectedValuesCopy.tripType = "3";
                setSelectedValues(selectedValuesCopy);
              } catch (ex) {
                console.log(ex);
              }
              let vehicleindex = vehicleTypeList.findIndex(
                (obj: { id: any }) => obj.id === selectedValues.vehicleType
              );
              let vechicletype: any = vehicleTypeList[vehicleindex];
              copyinterCityList[i].addkmcharge = vechicletype.addkmcharge;
              copyinterCityList[i].addminutecharge =
                vechicletype.addminutecharge;
              copyinterCityList[i].approximatefare =
                copyinterCityList[i].basefare;
              copyinterCityList[i].tempapproximatefare =
                copyinterCityList[i].basefare;
              calculateinterFare(
                distance,
                copyinterCityList[i].basekm,
                copyinterCityList[i].basefare,
                vechicletype.addkmcharge,
                copyinterCityList[i]
              );
              return copyinterCityList[i];
            }
          }
        }
      }
    }
  };

  const calculateintercityReturnFare = (distance: any) => {
    if (distance != undefined) {
      let copyinterCityList: any = interCityReturnList;
      distance = distance.replace(/[^\d.-]/g, "");
      for (let i = 0; i < copyinterCityList.length; i++) {
        let a = i + 1;
        if (copyinterCityList.length > a) {
          if (
            Number(distance) >= copyinterCityList[i].tollerenckm &&
            Number(distance) < copyinterCityList[a].tollerenckm
          ) {
            try {
              let selectedValuesCopy = { ...selectedValues };
              selectedValuesCopy.distance = distance + " KM";
              selectedValuesCopy.intercitytype = copyinterCityList[i].basekm;
              selectedValuesCopy.intercity =
                copyinterCityList[i].basekm +
                "-" +
                copyinterCityList[i].basefare +
                "-" +
                Math.floor(copyinterCityList[i].baseminute / 60) +
                "." +
                (copyinterCityList[i].baseminute % 60) +
                " Hrs";
              selectedValuesCopy.tripType = "3";
              setSelectedValues(selectedValuesCopy);
            } catch (ex) {
              console.log(ex);
            }
            let vehicleindex = vehicleTypeList.findIndex(
              (obj: { id: any }) => obj.id === selectedValues.vehicleType
            );
            let vechicletype: any = vehicleTypeList[vehicleindex];
            copyinterCityList[i].addkmcharge = vechicletype.addkmcharge;
            copyinterCityList[i].addminutecharge = vechicletype.addminutecharge;
            copyinterCityList[i].approximatefare =
              copyinterCityList[i].basefare;
            copyinterCityList[i].tempapproximatefare =
              copyinterCityList[i].basefare;
            calculateinterFare(
              distance,
              copyinterCityList[i].basekm,
              copyinterCityList[i].basefare,
              vechicletype.addkmcharge,
              copyinterCityList[i]
            );
            return copyinterCityList[i];
          }
        } else if (copyinterCityList.length == a) {
          if (Number(distance) >= copyinterCityList[i].tollerenckm) {
            try {
              let selectedValuesCopy = { ...selectedValues };
              selectedValuesCopy.intercitytype = copyinterCityList[i].basekm;
              selectedValuesCopy.intercity =
                copyinterCityList[i].basekm +
                "-" +
                copyinterCityList[i].basefare +
                "-" +
                Math.floor(copyinterCityList[i].baseminute / 60) +
                "." +
                (copyinterCityList[i].baseminute % 60) +
                " Hrs";
              selectedValuesCopy.tripType = "3";
              setSelectedValues(selectedValuesCopy);
            } catch (ex) {
              console.log(ex);
            }
            let vehicleindex = vehicleTypeList.findIndex(
              (obj: { id: any }) => obj.id === selectedValues.vehicleType
            );
            let vechicletype: any = vehicleTypeList[vehicleindex];
            copyinterCityList[i].addkmcharge = vechicletype.addkmcharge;
            copyinterCityList[i].addminutecharge = vechicletype.addminutecharge;
            copyinterCityList[i].approximatefare =
              copyinterCityList[i].basefare;
            copyinterCityList[i].tempapproximatefare =
              copyinterCityList[i].basefare;
            calculateinterFare(
              distance,
              copyinterCityList[i].basekm,
              copyinterCityList[i].basefare,
              vechicletype.addkmcharge,
              copyinterCityList[i]
            );
            return copyinterCityList[i];
          }
        }
      }
    }
  };

  const getPackageAndInterCityList = (city: any, vehicleType: any) => {
    const options = {
      params: {
        cityid: city,
        vehicletype: vehicleType,
      },
    };
    axiosGet(UrlConstants.getInterCityList, options).then((resp: any) => {
      setInterCityList(resp);
    });
    axiosGet(UrlConstants.getInterCityReturnList).then((resp: any) => {
      setInterCityReturnList(resp);
    });
    const uri =
      UrlConstants.getPackagesList +
      "?vehicleid=" +
      vehicleType +
      "&cityid=" +
      city;
    axiosGet(uri).then((resp: any) => {
      setPackagesList(resp);
    });
    const uri2 =
      UrlConstants.getNightFareType +
      "?vehicleid=" +
      vehicleType +
      "&cityid=" +
      city;
    axiosGet(uri2).then((resp: any) => {
      setNightFareList(resp);
    });
  };

  const onVehicleTypeChange = (value: any, fullInfo: any) => {
    if (fullInfo == null) {
      fullInfo = [];
      fullInfo.others = value;
      value = value.id;
      if (fullInfo.others.city != undefined) {
        selectedValues.city = fullInfo.others.city;
      }
    }
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.addkmcharge = fullInfo.others.addkmcharge;
    selectedValuesCopy.addminutecharge = fullInfo.others.addminutecharge;
   
    setCalculatedMeterFareInfo(selectedValuesCopy);
    onAvailableCabsList(value);
    let distance: any = 0;
    distance = selectedValues?.distance;
    if (isNaN(selectedValues?.distance))
      distance = distance?.replace(/[^\d.-]/g, "");
    if (selectedValues.tripType == 1 || Number(distance) <= 25)
      calculateMeterFare(selectedValues.distance, selectedValues.city, value);
    else if (selectedValues.tripType == 2) {
      if (selectedValues.packagetype != undefined) {
        calculatedMeterFareInfo.addkmcharge = fullInfo.others.addkmcharge;
        calculatedMeterFareInfo.addminutecharge =
          fullInfo.others.addminutecharge;
        calculatepackageFare(
          selectedValues.distance,
          calculatedMeterFareInfo.basekm,
          calculatedMeterFareInfo.basefare,
          fullInfo.others.addkmcharge,
          calculatedMeterFareInfo
        );
      }
    } else if (selectedValues.tripType == 3) {
      if (selectedValues.intercitytype != undefined) {
        if (roundtripChecked) {
          calculatedMeterFareInfo.addkmcharge = fullInfo.others.addkmcharge;
          calculatedMeterFareInfo.addminutecharge =
            fullInfo.others.addminutecharge;
          calculateinterFare(
            Number(selectedValues.distance.replace(/[^\d.-]/g, "")) * 2,
            calculatedMeterFareInfo.basekm,
            calculatedMeterFareInfo.basefare,
            fullInfo.others.addkmcharge,
            calculatedMeterFareInfo
          );
        } else {
          calculatedMeterFareInfo.addkmcharge = fullInfo.others.addkmcharge;
          calculatedMeterFareInfo.addminutecharge =
            fullInfo.others.addminutecharge;
          calculateinterFare(
            selectedValues.distance.replace(/[^\d.-]/g, ""),
            calculatedMeterFareInfo.basekm,
            calculatedMeterFareInfo.basefare,
            fullInfo.others.addkmcharge,
            calculatedMeterFareInfo
          );
        }
      }
    }
    getTripTypeList(false, value, fullInfo.others);
    // checkTimeAndEnableAdditionalFare(selectedValues.time || defaultDateTime);
  };

  const onBranchChange = (
    value: any,
    fullInfo: any = null,
    isEditScreen: any = false
  ) => {
    if (!isEditScreen) {
      let selectedValuesCopy = { ...selectedValues };
      selectedValuesCopy.city = value;
      setSelectedValues(selectedValuesCopy);
    }
    getVechileTypeList(value, isEditScreen);

    const companyListUri = UrlConstants.getCompanyList + "?cityid=" + value;
    axiosGet(companyListUri).then((resp: any) => {
      setCompanyList(resp);
    });
    getLocations(value);
  };

  const getLocations = (value: any) => {
    const options = {
      params: {
        cityid: value,
      },
    };
    axiosGet(UrlConstants.getLocations, options).then((resp: any) => {
      setLocationsList(resp);
    });
  };

  const onCompanyChange = (value: any, fullInfo: any) => {
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.company = value;
    selectedValuesCopy.companyName = fullInfo.others.companyname;
    setSelectedValues(selectedValuesCopy);
  };
  const onLeavingMobileNumber = (e: any) => {
    let mobileno = selectedValues.mobileNo;
    if (selectedValues.mobileNo.length != 10) {
      setMobileLengthVal("Mobile number must be 10 digits!");
    } else {
      setMobileLengthVal("");
    }
  };
  const onMobileNumberChange = async (e: any) => {
    const formatedValue = e.target.value.replace(/[^0-9]/g, "");
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.mobileNo = formatedValue;
    setSelectedValues(selectedValuesCopy);
    if (formatedValue.length < 10) {
      return;
    }

    axiosGet(
      UrlConstants.getCustomerName +
        "?mobileno=" +
        Number(formatedValue) +
        "&cityid=" +
        selectedValues.city
    )
      .then(async (resp: any) => {
        getPackageAndInterCityList(resp[0].branchid, resp[0].vehicleid);
        let value = resp[0].triptype;
        let selectedValuesCopy = { ...selectedValues };
        if (value === "1")
          calculateMeterFare(
            resp[0].distance,
            resp[0].branchid,
            resp[0].vehicleid
          );
        if (value === "3") {
          if (roundtripChecked) {
            let distance = Number(resp[0].distance.replace(/[^\d.-]/g, "")) * 2;
            let distancestring = distance.toString() + " KM";
            calculateintercityReturnFare(distancestring);
          } else {
            let a = await calculateintercityFare(resp[0].distance);
            if (a != undefined && a != null) {
              selectedValuesCopy.intercitytype = a.basekm;
              selectedValuesCopy.intercity =
                a.basekm +
                "-" +
                a.basefare +
                "-" +
                Math.floor(a.baseminute / 60) +
                "." +
                (a.baseminute % 60) +
                " Hrs";
            }
          }
        }
        selectedValuesCopy.goodsType = resp[0].goodsid;
        selectedValuesCopy.goodsTypeName = resp[0].goodsname;
        selectedValuesCopy.customerName = resp[0].name;
        selectedValuesCopy.mobileNo = formatedValue;
        if (
          resp[0].companyname == "" ||
          resp[0].companyname == null ||
          resp[0].companyname == undefined
        )
          selectedValuesCopy.company = "new";
        else selectedValuesCopy.company = resp[0].companyname;

        selectedValuesCopy.fromlocName = resp[0].fromloc;
        selectedValuesCopy.tolocName = resp[0].toloc;
        selectedValuesCopy.fromloc = resp[0].fromlocid;
        selectedValuesCopy.toloc = resp[0].tolocid;
        selectedValuesCopy.vehicleType = resp[0].vehicleid;
        selectedValuesCopy.tripType = resp[0].triptype;
        selectedValuesCopy.intercitytype = resp[0].intercitytype;
        selectedValuesCopy.packagetype = resp[0].packagetype;
        selectedValuesCopy.alternativeNo = resp[0].alternativemobileno;
        selectedValuesCopy.refferedby = resp[0].refferedby;
        selectedValuesCopy.city = resp[0].branchid;
        selectedValuesCopy.distance = resp[0].distance;
        selectedValuesCopy.remarks = resp[0].remarks;
        setSelectedValues(selectedValuesCopy);
        setGSTChecked(resp[0].isgst);
        setTollGateChecked(resp[0].istollgate);
        setCvChecked(resp[0].iscv);
        //  setIs30Checked(resp[0].isadditionalfare);
        setCreditChecked(resp[0].iscredit);
        setCustomerName(resp[0].name);
        getTripTypeList(true);
        setHistoryList(resp[0].triphistory);
      })
      .catch(function (error: any) {
        setCustomerName("");
        let selectedValuesCopy = { ...selectedValues };
        selectedValuesCopy.mobileNo = formatedValue;
        selectedValuesCopy.company = "new";
        selectedValuesCopy.companyName = "new";
        delete selectedValuesCopy.fromlocName;
        delete selectedValuesCopy.tolocName;
        delete selectedValuesCopy.fromloc;
        delete selectedValuesCopy.toloc;
        delete selectedValuesCopy.goodsType;
        delete selectedValuesCopy.goodsTypeName;
        delete selectedValuesCopy.alternativeNo;
        delete selectedValuesCopy.remarks;
        delete selectedValuesCopy.refferedby;
        selectedValuesCopy.vehicleType = 1;
        selectedValuesCopy.tripType = "1";
        setSelectedValues(selectedValuesCopy);
        setHistoryList([]);
        if (error.response) {
          console.log(error.response.data, error.response.status);
        }
      });
  };
  const onCustomerNameChange = (e: any) => {
    const validInput = e.target.value.replace(/[0-9]/g, "");
    setCustomerName(validInput);
  };

  const onGoodsChange = (value: any, fullInfo: any) => {
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.goodsType = value;
    selectedValuesCopy.goodsTypeName = fullInfo.others.goodsname;
    setSelectedValues(selectedValuesCopy);
  };

  const onDateChange = (value: any) => {
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.date = value;
    setSelectedValues(selectedValuesCopy);
  };

  const onTimeChange = (value: any) => {
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.time = value;
    setSelectedValues(selectedValuesCopy);
    checkTimeAndEnableAdditionalFare(value);
  };
  const onAlternateNumberChange = (e: any) => {
    const formatedValue = e.target.value.replace(/[^0-9]/g, "");
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.alternativeNo = formatedValue;
    setSelectedValues(selectedValuesCopy);
  };
  const onReferredByChange = (e: any) => {
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.refferedby = e.target.value;
    setSelectedValues(selectedValuesCopy);
  };

  const addCompanyChange = (e: any) => {
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.newcompanyName = e.target.value;
    setSelectedValues(selectedValuesCopy);
  };
  const onRemarksChange = (e: any) => {
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.remarks = e.target.value;
    setSelectedValues(selectedValuesCopy);
  };

  const onFromLocationChange = (value: any, options: any) => {
    setSelectedFromLocation(options.others);
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.fromloc = value;
    selectedValuesCopy.fromlocName = options.others.PlaceName;
    selectedValuesCopy.fromloclat = options.others.Latitude;
    selectedValuesCopy.fromloclong = options.others.Longitude;
    setSelectedValues(selectedValues);
    if (selectedToLocation.ID != undefined) {
      calculateDistance(options.others, selectedToLocation, value);
    } else if (selectedValues.toloc != undefined) {
      const selectedtoloc = locationsList.filter(
        (x: any) => x.ID === selectedValues.toloc
      )[0];
      calculateDistance(options.others, selectedtoloc, value);
    }
    onAvailableCabsList(selectedValues.vehicleType, options.others);
  };

  const onToLocationChange = (value: any, options: any) => {
    setSelectedToLocation(options.others);
    if (
      selectedFromLocation.ID == undefined &&
      selectedValues.fromloc != undefined
    ) {
      let index = locationsList.findIndex(
        (x: { ID: string }) => x.ID === selectedValues.fromloc
      );
      let selectedfrom = locationsList[index];
      calculateDistance(selectedfrom, options.others, value);
    }
    if (selectedFromLocation.ID != undefined) {
      calculateDistance(selectedFromLocation, options.others, value);
    }
  };

  const calculateDistance = (
    fromLocation: any,
    toLocation: any,
    value: any
  ) => {
    setGSTChecked(false);
    //setIs30Checked(false);
    const uri =
      UrlConstants.getDistance +
      "?fromlat=" +
      fromLocation.Latitude +
      "&fromlong=" +
      fromLocation.Longitude +
      "&tolat=" +
      toLocation.Latitude +
      "&tolong=" +
      toLocation.Longitude;
    axiosGet(uri)
      .then(async (resp: any) => {
        let selectedValuesCopy = { ...selectedValues };
        selectedValuesCopy.fromloc = fromLocation.ID;
        selectedValuesCopy.fromlocName = fromLocation.PlaceName;
        selectedValuesCopy.distance = resp[0].distance;
        let triptype = "1";
        let tempdistance = Number(resp[0].distance.replace(/[^\d.-]/g, ""));
        if (Number(resp[0].distance.replace(/[^\d.-]/g, "")) > 21) {
          let tripTypeList1 = tripTypeList;
          let index = 0;
          let m = Math.sign(index);
          if (Number(resp[0].distance.replace(/[^\d.-]/g, "")) > 25) {
            index = tripTypeList1.findIndex(
              (x: { id: string }) => x.id === "1"
            );
            m = Math.sign(index);
            if (m != -1) tripTypeList1.splice(index, 1);
          }
          triptype = "3";
          if (Number(resp[0].distance.replace(/[^\d.-]/g, "")) > 40) {
            index = tripTypeList1.findIndex(
              (x: { id: string }) => x.id === "2"
            );
            m = Math.sign(index);
            if (m != -1) tripTypeList1.splice(index, 1);
          } else {
            axiosGet(UrlConstants.getTripTypeList).then((resp: any) => {
              if (tempdistance > 25) {
                let index = resp.findIndex((x: { id: string }) => x.id === "1");
                let m = Math.sign(index);
                if (m != -1) resp.splice(index, 1);
              }
              setTripTypeList(resp);
            });
          }
        } else {
          triptype = "1";
          let index = tripTypeList.findIndex(
            (x: { id: string }) => x.id === "1"
          );
          let m = Math.sign(index);
          if (m == -1) {
            axiosGet(UrlConstants.getTripTypeList).then((resp: any) => {
              setTripTypeList(resp);
            });
          }
        }
        selectedValuesCopy.toloc = value;
        selectedValuesCopy.tolocName = toLocation.PlaceName;
        if (triptype == "3") {
          let tt;
          if (roundtripChecked) {
            let distance;
            if (isNaN(selectedValues.distance))
              distance = Number(resp[0].distance.replace(/[^\d.-]/g, "")) * 2;
            else distance = Number(resp[0].distance) * 2;
            let distancestring = distance.toString() + " KM";
            tt = calculateintercityReturnFare(distancestring);
          } else {
            tt = await calculateintercityFare(resp[0].distance);
            selectedValuesCopy.intercitytype = tt.basekm;
          }
        }
        if (triptype == "2") {
          if (selectedValuesCopy.packagetype != undefined) {
            calculatepackageFare(
              resp[0].distance,
              calculatedMeterFareInfo.basekm,
              calculatedMeterFareInfo.basefare,
              calculatedMeterFareInfo.addkmcharge,
              calculatedMeterFareInfo
            );
          }
        }
        selectedValuesCopy.tripType = triptype;
        setSelectedValues(selectedValuesCopy);
        if (
          selectedValues.vehicleType != undefined &&
          triptype != undefined &&
          fromLocation.ID != undefined &&
          value != undefined
        ) {
          if (triptype == "1") {
            calculateMeterFare(
              resp[0].distance,
              selectedValues.city,
              selectedValues.vehicleType
            );
          }
        }
      })
      .catch(() => {
        let selectedValuesCopy = { ...selectedValues };
        selectedValuesCopy.toloc = value;
        selectedValuesCopy.tolocName = toLocation.PlaceName;
        setSelectedValues(selectedValuesCopy);
      });
  };

  const calculateMeterFare = (distance: any, city: any, vehicleId: any) => {
    if (isNaN(distance)) {
      distance = distance?.replace(/[^\d.-]/g, "");
    }
    if (distance != undefined) {
      const uri =
        UrlConstants.getMeterFare +
        "?distance=" +
        distance +
        "&branchid=" +
        city +
        "&vehicleid=" +
        vehicleId;
      axiosGet(uri)
        .then((resp: any) => {
          resp[0].tempapproximatefare = resp[0].approximatefare;
          // Peak Time check
          const format = "HH:mm:ss";
          const time = selectedValues.time || defaultDateTime;
          // const afterTime = moment("10:00:00", format);
          // const beforeTime = moment("11:00:00", format);

          // console.log("outside condition : " + is30Checked);
          // if (time.isBetween(afterTime, beforeTime) || is30Checked) {
          //   console.log("Inside condition");
          //   resp[0].servicefare = (resp[0].approximatefare * 30) / 100;
          //   resp[0].approximatefare = Number(resp[0].approximatefare) + Number(resp[0].servicefare);
          // }
          setCalculatedMeterFareInfo(resp[0]);
          setGSTChecked(false);
          //setIs30Checked(false);
        })
        .catch(function (error: any) {
          if (error.response) {
            console.log(error.response.data, error.response.status);
          }
        });
    }
  };

  const calculatepackageFare = (
    distance: any,
    BaseKM: any,
    BaseFare: any,
    AddKMCharge: any,
    packlist: any
  ) => {
    if (distance != undefined) {
      var approximate_fare = BaseFare;
      if (isNaN(distance)) distance = distance.replace(/[^\d.-]/g, "");
      var KM = Number(BaseKM);
      if (KM >= Number(distance)) approximate_fare = approximate_fare;
      else if (KM < Number(distance)) {
        var extraKM = Number(distance) - KM;
        approximate_fare =
          Number(approximate_fare) + extraKM * Number(AddKMCharge);
      }
      packlist.approximatefare = approximate_fare;
      packlist.tempapproximatefare = approximate_fare;
      // Peak Time check
      //   const format = "HH:mm:ss";
      //   const time = selectedValues.time || defaultDateTime;
      //   const afterTime = moment("22:00:00", format);
      //   const beforeTime = moment("07:00:00", format);

      //   console.log("outside condition : "+is30Checked);
      // if (time.isBetween(afterTime, beforeTime) || is30Checked) {
      //   console.log("Inside condition");
      //   packlist.servicefare = (packlist.approximatefare * 30) / 100;
      //   packlist.approximatefare = Number(packlist.approximatefare) + Number(packlist.servicefare);
      // }
      setCalculatedMeterFareInfo(packlist);
    }
  };
  const calculateNightFare = (
    distance: any,
    BaseKM: any,
    BaseFare: any,
    AddKMCharge: any,
    AddMinuteCharge: any,
    Nightlist: any
  ) => {
    if (distance != undefined) {
      var approximate_fare = BaseFare;
      if (isNaN(distance)) distance = distance.replace(/[^\d.-]/g, "");
      var KM = Number(BaseKM);
      if (KM >= Number(distance)) approximate_fare = approximate_fare;
      else if (KM < Number(distance)) {
        var extraKM = Number(distance) - KM;
        approximate_fare =
          Number(approximate_fare) + extraKM * Number(AddKMCharge);
      }
      Nightlist.approximatefare = approximate_fare;
      Nightlist.tempapproximatefare = approximate_fare;
      // Peak Time check
      //   const format = "HH:mm:ss";
      //   const time = selectedValues.time || defaultDateTime;
      //   const afterTime = moment("22:00:00", format);
      //   const beforeTime = moment("07:00:00", format);

      //   console.log("outside condition : "+is30Checked);
      // if (time.isBetween(afterTime, beforeTime) || is30Checked) {
      //   console.log("Inside condition");
      //   packlist.servicefare = (packlist.approximatefare * 30) / 100;
      //   packlist.approximatefare = Number(packlist.approximatefare) + Number(packlist.servicefare);
      // }
      setCalculatedMeterFareInfo(Nightlist);
    }
  };
  const calculateinterFare = (
    distance: any,
    BaseKM: any,
    BaseFare: any,
    AddKMCharge: any,
    packlist: any
  ) => {
    var approximate_fare = BaseFare;
    var KM = Number(BaseKM);
    if (KM >= Number(distance)) approximate_fare = approximate_fare;
    else if (KM < Number(distance)) {
      var extraKM = Number(distance) - KM;
      approximate_fare =
        Number(approximate_fare) + extraKM * Number(AddKMCharge);
    }
    packlist.approximatefare = approximate_fare;
    packlist.tempapproximatefare = approximate_fare;
    // Peak Time check
    // const format = "HH:mm:ss";
    // const time = selectedValues.time || defaultDateTime;
    // const afterTime = moment("22:00:00", format);
    // const beforeTime = moment("07:00:00", format);

    // console.log("outside condition : "+is30Checked);
    // if (time.isBetween(afterTime, beforeTime) || is30Checked) {
    //   console.log("Inside condition");
    //   packlist.servicefare = (packlist.approximatefare * 30) / 100;
    //   packlist.approximatefare = Number(packlist.approximatefare) + Number(packlist.servicefare);
    // }
    setCalculatedMeterFareInfo(packlist);
  };

  const onInterCityChange = (value: any, fullInfo: any) => {
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.intercitytype = value;
    selectedValuesCopy.intercity = fullInfo.children;
    selectedValuesCopy.distance = value + " KM";
    setSelectedValues(selectedValuesCopy);
    let vehicleindex = vehicleTypeList.findIndex(
      (obj: { id: any }) => obj.id === selectedValues.vehicleType
    );
    let vechicletype: any = vehicleTypeList[vehicleindex];
    let interindex = interCityList.findIndex(
      (obj: { basekm: any }) => obj.basekm === value
    );
    let copyinterCityList: any = interCityList[interindex];
    copyinterCityList.addkmcharge = vechicletype.addkmcharge;
    copyinterCityList.addminutecharge = vechicletype.addminutecharge;
    copyinterCityList.approximatefare = copyinterCityList.basefare;
    copyinterCityList.tempapproximatefare = copyinterCityList.basefare;
    calculateinterFare(
      selectedValues.distance,
      copyinterCityList.basekm,
      copyinterCityList.basefare,
      vechicletype.addkmcharge,
      copyinterCityList
    );
    setGSTChecked(false);
    // setIs30Checked(false);
  };

  const onPackageChange = (value: any) => {
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.packagetype = value;
    selectedValuesCopy.tripType = "2";
    setSelectedValues(selectedValuesCopy);
    let selectedindex = packagesList.findIndex(
      (obj: { id: any }) => obj.id === value
    );
    let vehicleindex = vehicleTypeList.findIndex(
      (obj: { id: any }) => obj.id === selectedValues.vehicleType
    );
    let packlist: any = packagesList[selectedindex];
    let vechicletype: any = vehicleTypeList[vehicleindex];
    packlist.addkmcharge = vechicletype.addkmcharge;
    packlist.addminutecharge = vechicletype.addminutecharge;
    packlist.approximatefare = packlist.basefare;
    packlist.tempapproximatefare = packlist.basefare;
    calculatepackageFare(
      selectedValues.distance,
      packlist.basekm,
      packlist.basefare,
      vechicletype.addkmcharge,
      packlist
    );
    setGSTChecked(false);
    // setIs30Checked(false);
  };
  const onNightFareChange = (value: any) => {
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.Nighttype = value;
    selectedValuesCopy.tripType = "4";
    setSelectedValues(selectedValuesCopy);
    let selectedindex = NightFareList.findIndex(
      (obj: { id: any }) => obj.id === value
    );
    let Nightlist: any = NightFareList[selectedindex];
    Nightlist.approximatefare = Nightlist.basefare;
    Nightlist.tempapproximatefare = Nightlist.basefare;
    calculateNightFare(
      selectedValues.distance,
      Nightlist.basekm,
      Nightlist.basefare,
      Nightlist.AddKMCharge,
      Nightlist.AddMinuteCharge,
      Nightlist
    );
    setGSTChecked(false);
    // setIs30Checked(false);
  };
  const moreBookingConfirmation = () => {
    Modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content:
        "You have selected " +
        noOfBookings +
        " vehicle for booking , Do you want to continue?",
      okText: "Yes",
      cancelText: "No",
      onOk: () => addBooking(),
    });
  };

  // const on30perChange = (value: any) => {
  //  // setIs30Checked(value);
  //   if (value) {
  //     calculatedMeterFareInfo.servicefare = Math.round(((Number(calculatedMeterFareInfo.tempapproximatefare) / 100) * 30 + Number.EPSILON) * 100) / 100;
  //     calculatedMeterFareInfo.approximatefare = Math.round((Number(calculatedMeterFareInfo.approximatefare) + (Number(calculatedMeterFareInfo.tempapproximatefare) / 100) * 30 + Number.EPSILON) * 100) / 100;

  //     console.log(calculatedMeterFareInfo.approximatefare);
  //   }
  //   else {
  //     calculatedMeterFareInfo.servicefare = 0;
  //     calculatedMeterFareInfo.approximatefare = Math.round((Number(calculatedMeterFareInfo.approximatefare) - (Number(calculatedMeterFareInfo.tempapproximatefare) / 100) * 30 + Number.EPSILON) * 100) / 100;
  //   }
  // };

  const ongstChange = (value: any) => {
    setGSTChecked(value);
    if (value) {
      calculatedMeterFareInfo.gstfare =
        Math.round(
          ((Number(calculatedMeterFareInfo.tempapproximatefare) / 100) * 30 +
            Number.EPSILON) *
            100
        ) / 100;
      calculatedMeterFareInfo.approximatefare =
        Math.round(
          (Number(calculatedMeterFareInfo.approximatefare) +
            (Number(calculatedMeterFareInfo.tempapproximatefare) / 100) * 30 +
            Number.EPSILON) *
            100
        ) / 100;
    } else {
      calculatedMeterFareInfo.gstfare = 0;
      calculatedMeterFareInfo.approximatefare =
        Math.round(
          (Number(calculatedMeterFareInfo.approximatefare) -
            (Number(calculatedMeterFareInfo.tempapproximatefare) / 100) * 30 +
            Number.EPSILON) *
            100
        ) / 100;
    }
  };

  const onRoundtripChange = (value: any) => {
    let distance = selectedValues.distance;
    if (value && selectedValues.distance != undefined) {
      if (isNaN(selectedValues.distance))
        distance = Number(selectedValues.distance.replace(/[^\d.-]/g, "")) * 2;
      else distance = Number(selectedValues.distance) * 2;
    } else if (roundtripChecked && selectedValues.distance != undefined) {
      if (isNaN(selectedValues.distance))
        distance = Number(selectedValues.distance.replace(/[^\d.-]/g, "")) / 2;
      else distance = Number(selectedValues.distance) / 2;
    }
    if (selectedValues.tripType == "1") {
      let copyselectedvalues = { ...selectedValues };
      copyselectedvalues.distance = distance + " KM";
      setSelectedValues(copyselectedvalues);
      calculateMeterFare(
        distance,
        selectedValues.city,
        selectedValues.vehicleType
      );
    } else if (selectedValues.tripType == "3") {
      if (value && selectedValues.distance != undefined) {
        if (isNaN(selectedValues.distance))
          distance =
            Number(selectedValues.distance.replace(/[^\d.-]/g, "")) * 2;
        else distance = Number(selectedValues.distance) * 2;
        let distancestring = distance.toString() + " KM";
        calculateintercityReturnFare(distancestring);
      } else {
        calculateintercityFare(distance + " KM");
      }
    }
    setroundtripChecked(value);
  };

  const addBooking = () => {
    try {
      if (!validateInput()) {
        return;
      }
      let tripdate = selectedValues.date
        ? selectedValues.date.format("YYYY-MM-DD")
        : defaultDateTime.format("YYYY-MM-DD");
      let triptime = selectedValues.time
        ? selectedValues.time.format("HH:mm:ss") /* || selectedValues.time */
        : defaultDateTime.format("HH:mm:ss");
      let datetime = moment(tripdate + " " + triptime).format();
      if (isEditBooking) datetime = moment(tripdate).format("YYYY-MM-DD");
      const options: any = {
        data: {
          name: customerName,
          fromloc: selectedValues.fromlocName,
          toloc: selectedValues.tolocName,
          tripdate: datetime,
          mobileno: selectedValues.mobileNo,
          branchid: selectedValues.city,
          distance: selectedValues.distance,
          fare: calculatedMeterFareInfo.approximatefare,
          username: username,
          goodstype: selectedValues.goodsTypeName,
          triptime: triptime /* selectedValues.time
          ? selectedValues.time.format("HH:mm:")
          : defaultDateTime.format("HH:mm") */,
          triptype: selectedValues.tripType,
          vechicletype: selectedValues.vehicleType,
          remark: selectedValues.remarks,
          alternativemobno: selectedValues.alternativeNo,
          iscredit: creditChecked || false,
          refferedby: selectedValues.refferedby,
          companyname:
            selectedValues.companyName == "new"
              ? selectedValues.newcompanyName
              : selectedValues.companyName == ""
              ? selectedValues.newcompanyName
              : selectedValues.companyName == undefined
              ? selectedValues.newcompanyName
              : selectedValues.companyName == null
              ? selectedValues.newcompanyName
              : selectedValues.companyName,
          intercitytype: selectedValues.intercity,
          packagetype: selectedValues.packagetype,
          nighttype: selectedValues.Nighttype,
          iscv: cvChecked,
          isadditionalfare: false,
          istollgate: tollGateChecked,
          isgst: gstChecked,
          noofvechicles: noOfBookings,
        },
      };
      let uri = "";
      if (isEditBooking) {
        uri = UrlConstants.editBooking;
        options.data.bookid = selectedValues.bookingId;
        options.data.updatedby = username;
      } else {
        uri = UrlConstants.addNewBooking;
      }
      axiosPost(uri, options)
        .then((resp: any) => {
          // getBranchDetails();
          // onGoodsList();
          const copiedValues = { ...selectedValues };
          let newValues: any = {};
          newValues.city = copiedValues.city;
          newValues.mobileNo = "";
          setSelectedValues(newValues);
          setCustomerName("");
          setCalculatedMeterFareInfo({});
          // getVechileTypeList(copiedValues.city,false);

          const notificationInfo1: any = {
            header:
              resp[0].sms == false
                ? "SMS sending failed"
                : "SMS Sent Successfully",
            description:
              resp[0].sms == false
                ? "SMS sending failed"
                : "SMS Sent Successfully",
          };

          const notificationInfo: any = {
            header: isEditBooking
              ? "Edit Booking Successfully"
              : "Add New Booking Successfully",
            description: isEditBooking
              ? "Your booking is successfully edited"
              : "You can see all the bookings by clicking below button",
          };
          if (!isEditBooking) {
            notificationInfo.buttonText = "View New Booking list";
            notificationInfo.path = "/newBooking";
          }
          openNotificationWithIcon("Success", notificationInfo);
          openNotificationWithIcon(
            resp[0].sms == false ? "Error" : "Success",
            notificationInfo1
          );
          onBookingNavigation("/newBooking");
          onBookingNavigation("/addBooking");
          if (isEditBooking) {
            history.goBack();
          }
        })
        .catch(function (error: any) {
          if (error.response) {
            console.log(error.response.data, error.response.status);
          }
          const notificationInfo = {
            header: isEditBooking
              ? "Edit Booking failed"
              : "Add New Booking Failed",
            description: isEditBooking
              ? "Error Occurred while Editing Booking"
              : "Error Occurred while Adding New Booking",
          };
          openNotificationWithIcon("Error", notificationInfo);
        });
    } catch (ex) {
      console.log(ex);
    }
  };

  const addEnquiry = () => {
    if (!validateInput()) {
      return;
    }
    let tripdate = selectedValues.date
      ? selectedValues.date.format("YYYY-MM-DD")
      : defaultDateTime.format("YYYY-MM-DD");
    let triptime = selectedValues.time
      ? selectedValues.time.format("HH:mm:ss")
      : defaultDateTime.format("HH:mm:ss");
    let datetime = moment(tripdate + " " + triptime).format();
    const options = {
      data: {
        name: customerName,
        fromloc: selectedValues.fromlocName,
        toloc: selectedValues.tolocName,
        tripdate: datetime,
        mobileno: selectedValues.mobileNo,
        branchid: selectedValues.city,
        distance: selectedValues.distance,
        fare: calculatedMeterFareInfo.approximatefare,
        username: username,
        goodstype: selectedValues.goodsTypeName,
        triptime: triptime /* selectedValues.time
          ? selectedValues.time.format()
          : defaultDateTime.format("HH:mm") */,
        triptype: selectedValues.tripType,
        vechicletype: selectedValues.vehicleType,
        remark: selectedValues.remarks,
        alternativemobno: selectedValues.alternativeNo,
        iscredit: creditChecked,
        refferedby: selectedValues.refferedby,
        //companyname: selectedValues.companyName,
        intercitytype: selectedValues.intercity,
        packagetype: selectedValues.packagetype,
        iscv: cvChecked,
        isadditionalfare: false,
        istollgate: tollGateChecked,
        isgst: gstChecked,
        noofvechicles: noOfBookings,
      },
    };
    axiosPost(UrlConstants.addEnquiry, options)
      .then((resp: any) => {
        const copiedValues = { ...selectedValues };
        let newValues: any = {};
        newValues.city = copiedValues.city;
        newValues.mobileNo = " ";
        setSelectedValues(newValues);
        setCustomerName("");
        setCalculatedMeterFareInfo({});

        const notificationInfo = {
          header: "Add To Enquiry Successfully",
          description:
            "You can see all the Enquiry list by clicking below button",
          buttonText: "View Enquiry list",
          path: "/enquiry",
        };
        /* const notificationInfo1: any = {
          header: "SMS Sent Successfully"
        }
        if (resp.sms == false) {
          notificationInfo1.description = "SMS sending failed"
        }
        else
          notificationInfo1.description = "SMS sent successfully" */
        const notificationInfo1: any = {
          header:
            resp[0].sms == false
              ? "SMS sending failed"
              : "SMS Sent Successfully",
          description:
            resp[0].sms == false
              ? "SMS sending failed"
              : "SMS Sent Successfully",
        };

        openNotificationWithIcon("Success", notificationInfo);
        openNotificationWithIcon(
          resp[0].sms == false ? "Error" : "Success",
          notificationInfo1
        );
      })
      .catch((error: any) => {
        const notificationInfo = {
          header: "Add To Enquiry Failed",
          description: "Error Occurred while Adding Enquiry",
        };
        openNotificationWithIcon("Error", notificationInfo);
      });
  };
  const addToRac = () => {
    if (!validateInput()) {
      return;
    }
    let tripdate = selectedValues.date
      ? selectedValues.date.format("YYYY-MM-DD")
      : defaultDateTime.format("YYYY-MM-DD");
    let triptime = selectedValues.time
      ? selectedValues.time.format("HH:mm:ss")
      : defaultDateTime.format("HH:mm:ss");
    let datetime = moment(tripdate + " " + triptime).format();
    const options = {
      data: {
        name: customerName,
        fromloc: selectedValues.fromlocName,
        toloc: selectedValues.tolocName,
        tripdate: datetime,
        mobileno: selectedValues.mobileNo,
        branchid: selectedValues.city,
        distance: selectedValues.distance,
        fare: calculatedMeterFareInfo.approximatefare,
        username: username,
        goodstype: selectedValues.goodsTypeName,
        triptime: triptime /* selectedValues.time
          ? selectedValues.time.format()
          : defaultDateTime.format("HH:mm:ss") */,
        triptype: selectedValues.tripType,
        vechicletype: selectedValues.vehicleType,
        remark: selectedValues.remarks,
        alternativemobno: selectedValues.alternativeNo,
        iscredit: creditChecked,
        refferedby: selectedValues.refferedby,
        companyname: selectedValues.companyName,
        intercitytype: selectedValues.intercity,
        packagetype: selectedValues.packagetype,
        iscv: cvChecked,
        isadditionalfare: false,
        istollgate: tollGateChecked,
        isgst: gstChecked,
        noofvechicles: noOfBookings,
      },
    };
    axiosPost(UrlConstants.addToRac, options)
      .then((resp: any) => {
        const copiedValues = { ...selectedValues };
        let newValues: any = {};
        newValues.city = copiedValues.city;
        newValues.mobileNo = " ";
        setSelectedValues(newValues);
        setCustomerName("");
        setCalculatedMeterFareInfo({});
        const notificationInfo = {
          header: "Add To RAC Successfully",
          description:
            "You can see all the RAC bookings by clicking below button",
          buttonText: "View RAC list",
          path: "/racBooking",
        };
        const notificationInfo1: any = {
          header:
            resp[0].sms == false
              ? "SMS sending failed"
              : "SMS Sent Successfully",
          description:
            resp[0].sms == false
              ? "SMS sending failed"
              : "SMS Sent Successfully",
        };
        /*  if (resp.sms == false) {
           notificationInfo1.description = "SMS sending failed"
         }
         else
           notificationInfo1.description = "SMS sent successfully" */
        openNotificationWithIcon("Success", notificationInfo);
        openNotificationWithIcon(
          resp[0].sms == false ? "Error" : "Success",
          notificationInfo1
        );
      })
      .catch((error: any) => {
        const notificationInfo = {
          header: "Add To RAC Failed",
          description: "Error Occurred while Adding To RAC",
        };
        openNotificationWithIcon("Error", notificationInfo);
        openNotificationWithIcon("Success", notificationInfo);
      });
  };

  const validateInput = () => {
    if (
      customerName &&
      selectedValues.tolocName &&
      selectedValues.fromlocName &&
      selectedValues.mobileNo &&
      selectedValues.city &&
      selectedValues.distance &&
      selectedValues.goodsTypeName &&
      selectedValues.tripType &&
      selectedValues.vehicleType &&
      // selectedValues.companyName &&
      (selectedValues.date || defaultDateTime) &&
      (selectedValues.time || defaultDateTime)
    ) {
      if (selectedValues.tripType && selectedValues.tripType === "1") {
        setValidations(false);
        return true;
      } else if (
        (selectedValues.tripType === "3" && selectedValues.intercitytype) ||
        (selectedValues.tripType === "2" && selectedValues.packagetype) ||
        (selectedValues.tripType === "4" && selectedValues.Nighttype)
      ) {
        setValidations(false);
        return true;
      } else if (selectedValues.mobileNo.length != 10) {
        setValidations(true);
        return false;
      } else {
        setValidations(true);
        return false;
      }
    } else {
      setValidations(true);
      return false;
    }
  };

  const disabledDate = (current: any) => {
    // Can not select days before today and today
    return current && current < moment().endOf("day");
  };
  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not validate email!",
      number: "${label} is not a validate number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  const onBookingNavigation = (path: any) => {
    history.push("/booking" + path);
  };

  const openNotificationWithIcon = (type: any, info: any) => {
    const btn = info.buttonText && (
      <Button
        type="primary"
        size="small"
        onClick={() => onBookingNavigation(info.path)}
      >
        {info.buttonText}
      </Button>
    );
    if (type === "Success") {
      notification.success({
        message: info.header,
        description: info.description,
        // + " SMS : "+info.text,
        btn,
      });
    } else if (type === "Error") {
      notification.error({
        message: info.header,
        description: info.description,
        // + " SMS : "+info.text,
      });
    }
  };

  const onComapnySearch = (value: any) => {
    if (value) {
      var filtered = companyList.filter((x: any) =>
        String(x?.companyname?.toLowerCase()).startsWith(value?.toLowerCase())
      );
      setCompanySearchedList(filtered);
    } else {
      setCompanySearchedList([]);
    }
  };

  const onFromLocationSearch = (value: any) => {
    if (value) {
      // const searchedList = locationsList.filter(
      //   (x: any) => x.PlaceName.toLowerCase().indexOf(value) > -1
      // );
      var filtered = locationsList.filter((x: any) =>
        String(x.PlaceName.toLowerCase()).startsWith(value.toLowerCase())
      );
      setSearchFromLocationsList(filtered);
    } else {
      setSearchFromLocationsList([]);
    }
  };

  const onToLocationSearch = (value: any) => {
    if (value) {
      // const searchedList = locationsList.filter(
      //   (x: any) => x.PlaceName.toLowerCase().indexOf(value) > -1
      // );
      var filtered = locationsList.filter((x: any) =>
        String(x.PlaceName.toLowerCase()).startsWith(value.toLowerCase())
      );
      setSearchToLocationsList(filtered);
    } else {
      setSearchToLocationsList([]);
    }
  };

  const checkTimeAndEnableAdditionalFare = (dateandtime: any) => {
    const format = "HH:mm:ss";
    const time = dateandtime;
    const afterTime = moment("22:00:00", format);

    const beforeTime = moment("07:00:00", format);

    if (time.isBetween(beforeTime, afterTime)) {
      let tempobject = { ...calculatedMeterFareInfo };
      tempobject.approximatefare = Math.round(
        Number(tempobject.approximatefare) - Number(tempobject.servicefare)
      );
      tempobject.servicefare = 0;
      setCalculatedMeterFareInfo(tempobject);
    } else {
      let tempobject = { ...calculatedMeterFareInfo };
      tempobject.servicefare = (tempobject.approximatefare * 30) / 100;
      tempobject.approximatefare =
        Number(tempobject.approximatefare) + Number(tempobject.servicefare);
      setCalculatedMeterFareInfo(tempobject);
    }
  };
  const showGridModal = () => {
    setGridModalVisible(true);
    //  getAvailableCabList;
  };
  const modalReAssignColumns = [
    {
      title: "CabId",
      dataIndex: "cabid",
      key: "cabid",
      fixed: "left",
    },
    {
      title: "Vehicle",
      dataIndex: "cabtype",
      key: "cabtype",
    },
    {
      title: "Distance",
      dataIndex: "distance",
      key: "distance",
    },
  ];
  const getAvailableCabList = (rowinfo: any) => {
    const options = {
      params: {
        branchid: branchid,
        vehicletype: rowinfo[0].vehicletypeid,
      },
    };
    axiosGet(UrlConstants.getAvailableCabs, options).then((resp: any) => {
      resp.forEach((e: any) => {
        e.key = e.cabid;
        const distanceuri =
          UrlConstants.getDistance +
          "?fromlat=" +
          rowinfo[0].fromloclat +
          "&fromlong=" +
          rowinfo[0].fromloclong +
          "&tolat=" +
          e.latitude +
          "&tolong=" +
          e.longitude;
        axiosGet(distanceuri).then((res: any) => {
          e.distance = res[0].distance;
          setAvailableCabData([]);
          setAvailableCabData(resp);
        });
      });
    });
  };
  const hideModal = (isVisible: any) => {
    setGridModalVisible(isVisible);
  };

  function handleEnter(event: any) {
    if (event.keyCode === 13) {
      const form = event.target.form;
      const index = Array.prototype.indexOf.call(form, event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  }

  const renderBookingForm = () => {
    return (
      <Spin tip="Loading..." spinning={isLoading}>
        <Form
          form={form}
          name="advanced_search"
          className="ant-advanced-search-form"
          onFinish={onFinish}
          validateMessages={validateMessages}
        >
          <Row>
            <Col span={12} className="add-booking-left-section-column-form-col">
              <Form.Item>
                <Select
                  placeholder="Select Branch"
                  value={selectedValues.city}
                  onChange={onBranchChange}
                  disabled={disableFields}
                  onKeyDown={handleEnter}
                >
                  {branchList.map((branch: any) => (
                    <Option key={branch._id} value={branch.id}>
                      {branch.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12} className="add-booking-left-section-column-form-col">
              <Form.Item
                style={
                  showValidations && !selectedValues.mobileNo
                    ? { marginBottom: 0 }
                    : {}
                }
              >
                <span style={{ display: "none" }}>
                  {selectedValues.mobileNo}
                </span>
                <Input
                  value={selectedValues.mobileNo}
                  placeholder="Mobile Number"
                  onChange={onMobileNumberChange}
                  disabled={disableFields}
                  maxLength={10}
                  onKeyDown={handleEnter}
                  onBlur={onLeavingMobileNumber}
                ></Input>
                {showValidations && !selectedValues.mobileNo && (
                  <span className="form-error-validation">
                    Please input Mobile Number!
                  </span>
                )}
                <span className="form-error-validation">{mobileLengthVal}</span>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12} className="add-booking-left-section-column-form-col">
              <Form.Item
                style={
                  showValidations && !customerName ? { marginBottom: 0 } : {}
                }
              >
                <span style={{ display: "none" }}>{customerName}</span>
                <Input
                  //defaultValue={customerName}
                  value={customerName}
                  onChange={onCustomerNameChange}
                  placeholder="Customer Name"
                  disabled={disableFields}
                  onKeyDown={handleEnter}
                />
                {showValidations && !customerName && (
                  <span className="form-error-validation">
                    Please input Customer Name!
                  </span>
                )}
              </Form.Item>
            </Col>
            <Col span={12} className="add-booking-left-section-column-form-col">
              <Form.Item
                style={
                  showValidations && !selectedValues.company
                    ? { marginBottom: 0 }
                    : {}
                }
              >
                <span style={{ display: "none" }}>
                  {selectedValues.company}
                </span>
                <Select
                  placeholder="Customer Id"
                  showSearch
                  optionFilterProp="children"
                  disabled={disableFields}
                  showArrow={false}
                  value={selectedValues.company}
                  onSearch={onComapnySearch}
                  // filterOption={(input: any, option: any) => {
                  //   return option.children
                  //     ?.toLowerCase()
                  //     .indexOf(input.toLowerCase()) >= 0;
                  // }}
                  onChange={onCompanyChange}
                  notFoundContent={null}
                  onKeyDown={handleEnter}
                >
                  {companySearchedList.map((company: any) => (
                    <Option
                      key={company.id}
                      value={company.id}
                      others={company}
                    >
                      {company.companyname}
                    </Option>
                  ))}
                </Select>
                {showValidations && !selectedValues.company && (
                  <span className="form-error-validation">
                    Please input Customer Id!
                  </span>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12} className="add-booking-left-section-column-form-col">
              <Form.Item
                style={
                  showValidations && !selectedValues.fromlocName
                    ? { marginBottom: 0 }
                    : {}
                }
              >
                <span style={{ display: "none" }}>
                  {selectedValues.fromlocName}
                </span>
                <Select
                  showSearch
                  optionFilterProp="children"
                  onChange={onFromLocationChange}
                  disabled={disableFields}
                  value={selectedValues.fromlocName}
                  filterOption={(input: any, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  showArrow={false}
                  onSearch={onFromLocationSearch}
                  placeholder="Search From Location"
                  notFoundContent={null}
                  onKeyDown={handleEnter}
                >
                  {searchFromLocationsList.map((item: any) => (
                    <Option key={item._id} value={item.ID} others={item}>
                      {item.PlaceName}
                    </Option>
                  ))}
                </Select>
                {showValidations && !selectedValues.fromlocName && (
                  <span className="form-error-validation">
                    Please input From location!
                  </span>
                )}
              </Form.Item>
            </Col>
            <Col span={12} className="add-booking-left-section-column-form-col">
              <Form.Item
                style={
                  showValidations && !selectedValues.tolocName
                    ? { marginBottom: 0 }
                    : {}
                }
              >
                <span style={{ display: "none" }}>
                  {selectedValues.tolocName}
                </span>
                <Select
                  showSearch
                  optionFilterProp="children"
                  disabled={disableFields}
                  value={selectedValues.tolocName}
                  filterOption={(input: any, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  showArrow={false}
                  onSearch={onToLocationSearch}
                  onChange={onToLocationChange}
                  placeholder="Search To Location"
                  notFoundContent={null}
                  onKeyDown={handleEnter}
                >
                  {searchToLocationsList.map((item: any) => (
                    <Option key={item._id} value={item.ID} others={item}>
                      {item.PlaceName}
                    </Option>
                  ))}
                </Select>
                {showValidations && !selectedValues.tolocName && (
                  <span className="form-error-validation">
                    Please input To location!
                  </span>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12} className="add-booking-left-section-column-form-col">
              <Form.Item>
                {/* <span style={{ display: "none" }}>
                {selectedValues.date &&
                  selectedValues.date.format("MM/DD/YYYY")}
              </span> */}
                <DatePicker
                  defaultValue={defaultDateTime}
                  value={selectedValues.date}
                  disabled={disableFields}
                  disabledDate={disabledDate}
                  format={dateFormat}
                  onChange={onDateChange}
                />
              </Form.Item>
            </Col>
            <Col span={12} className="add-booking-left-section-column-form-col">
              <Form.Item>
                {/* <span style={{ display: "none" }}>
                {selectedValues.time &&
                  selectedValues.time.format("MM/DD/YYYY")}
              </span> */}
                <TimePicker
                  defaultValue={defaultDateTime}
                  value={selectedValues.time}
                  disabled={disableFields}
                  use12Hours
                  format="hh:mm A"
                  onChange={onTimeChange}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12} className="add-booking-left-section-column-form-col">
              <Form.Item
                style={
                  showValidations && !selectedValues.goodsType
                    ? { marginBottom: 0 }
                    : {}
                }
              >
                <span style={{ display: "none" }}>
                  {selectedValues.goodsType}
                </span>
                <Select
                  placeholder="Select Goods Type"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input: any, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  value={selectedValues.goodsType}
                  disabled={disableFields}
                  onChange={onGoodsChange}
                  onKeyDown={handleEnter}
                >
                  {goodsList.map((goods: any) => (
                    <Option
                      key={goods.goodsid.toString()}
                      value={goods.goodsid}
                      others={goods}
                    >
                      {goods.goodsname}
                    </Option>
                  ))}
                </Select>
                {showValidations && !selectedValues.goodsType && (
                  <span className="form-error-validation">
                    Please select Goods Type!
                  </span>
                )}
              </Form.Item>
            </Col>
            <Col span={12} className="add-booking-left-section-column-form-col">
              <Form.Item
                style={
                  showValidations && !selectedValues.vehicleType
                    ? { marginBottom: 0 }
                    : {}
                }
              >
                <span style={{ display: "none" }}>
                  {selectedValues.vehicleType}
                </span>
                <div style={{ flexDirection: "row", display: "flex" }}>
                  <Select
                    placeholder="Select Vehicle"
                    value={selectedValues.vehicleType}
                    //  disabled={disableFields}
                    onChange={onVehicleTypeChange}
                    onKeyDown={handleEnter}
                  >
                    {vehicleTypeList.map((vehicle: any) => (
                      <Option
                        key={vehicle.id}
                        value={vehicle.id}
                        others={vehicle}
                      >
                        {vehicle.vehicletype} - {vehicle.feet} -{" "}
                        {vehicle.loadcapacity}
                      </Option>
                    ))}
                  </Select>
                  {!isEditBooking && (
                    <Select
                      style={{ width: "30%", marginLeft: "0.5rem" }}
                      value={noOfBookings}
                      disabled={disableFields}
                      onChange={(value) => setNoOfBookings(value)}
                      onKeyDown={handleEnter}
                    >
                      <Option key={1} value={1}>
                        1
                      </Option>
                      <Option key={2} value={2}>
                        2
                      </Option>
                      <Option key={3} value={3}>
                        3
                      </Option>
                      <Option key={4} value={4}>
                        4
                      </Option>
                      <Option key={5} value={5}>
                        5
                      </Option>
                    </Select>
                  )}
                </div>
                {showValidations && !selectedValues.vehicleType && (
                  <span className="form-error-validation">
                    Please select Vehicle!
                  </span>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12} className="add-booking-left-section-column-form-col">
              <Form.Item
                style={
                  showValidations && !selectedValues.tripType
                    ? { marginBottom: 0 }
                    : {}
                }
              >
                <span style={{ display: "none" }}>
                  {selectedValues.tripType}
                </span>
                <Select
                  // defaultValue={
                  //   selectedValues.tripType && selectedValues.tripType
                  // }
                  value={selectedValues.tripType}
                  placeholder="Select Trip Type"
                  onChange={onTripTypeChange}
                  onKeyDown={handleEnter}
                >
                  {tripTypeList.map((trip: any) => (
                    <Option key={trip.id} value={trip.id}>
                      {trip.name}
                    </Option>
                  ))}
                </Select>
                {showValidations && !selectedValues.tripType && (
                  <span className="form-error-validation">
                    Please select Trip Type!
                  </span>
                )}
              </Form.Item>
            </Col>
            <Col span={12} className="add-booking-left-section-column-form-col">
              {(selectedValues.tripType === "3" ||
                selectedValues.tripType === "2" ||
                selectedValues.tripType === "4") && (
                <div>
                  {selectedValues.tripType === "2" && (
                    <Form.Item
                      style={
                        showValidations && !selectedValues.packagetype
                          ? { marginBottom: 0 }
                          : {}
                      }
                    >
                      <span style={{ display: "none" }}>
                        {selectedValues.packagetype}
                      </span>
                      <Select
                        value={selectedValues.packagetype}
                        placeholder="Package fare"
                        onChange={onPackageChange}
                        onKeyDown={handleEnter}
                      >
                        {packagesList.map((vehicle: any) => (
                          <Option key={vehicle.id} value={vehicle.id}>
                            {vehicle.package}
                          </Option>
                        ))}
                      </Select>
                      {showValidations && !selectedValues.packagetype && (
                        <span className="form-error-validation">
                          Please select Package Fare!
                        </span>
                      )}
                    </Form.Item>
                  )}
                  {selectedValues.tripType === "3" && (
                    <Form.Item
                      style={
                        showValidations && !selectedValues.intercitytype
                          ? { marginBottom: 0 }
                          : {}
                      }
                    >
                      <span style={{ display: "none" }}>
                        {selectedValues.intercitytype}
                      </span>
                      {roundtripChecked && (
                        <Select
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input: any, option: any) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          placeholder="Select Intercity Type"
                          value={selectedValues.intercitytype}
                          onChange={onInterCityChange}
                          onKeyDown={handleEnter}
                        >
                          {interCityReturnList.map((intercity: any) => (
                            <Option
                              key={intercity.basekm}
                              value={intercity.basekm}
                            >
                              {intercity.basekm +
                                "-" +
                                intercity.basefare +
                                "-" +
                                Math.floor(intercity.baseminute / 60) +
                                "." +
                                (intercity.baseminute % 60) +
                                " Hrs"}
                            </Option>
                          ))}
                        </Select>
                      )}
                      {!roundtripChecked && (
                        <Select
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input: any, option: any) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          placeholder="Select Intercity Type"
                          value={selectedValues.intercitytype}
                          onChange={onInterCityChange}
                          onKeyDown={handleEnter}
                        >
                          {interCityList.map((intercity: any) => (
                            <Option
                              key={intercity.basekm}
                              value={intercity.basekm}
                            >
                              {intercity.basekm +
                                "-" +
                                intercity.basefare +
                                "-" +
                                Math.floor(intercity.baseminute / 60) +
                                "." +
                                (intercity.baseminute % 60) +
                                " Hrs"}
                            </Option>
                          ))}
                        </Select>
                      )}
                      {showValidations && !selectedValues.intercitytype && (
                        <span className="form-error-validation">
                          Please select Intercity Type!
                        </span>
                      )}
                    </Form.Item>
                  )}
                  {selectedValues.tripType === "4" && (
                    <Form.Item
                      style={
                        showValidations && !selectedValues.Nighttype
                          ? { marginBottom: 0 }
                          : {}
                      }
                    >
                      <span style={{ display: "none" }}>
                        {selectedValues.Nighttype}
                      </span>
                      <Select
                        value={selectedValues.Nighttype}
                        placeholder="Night fare"
                        onChange={onNightFareChange}
                        onKeyDown={handleEnter}
                      >
                        {NightFareList.map((vehicle: any) => (
                          <Option key={vehicle.id} value={vehicle.id}>
                            {vehicle.package}
                          </Option>
                        ))}
                      </Select>
                      {showValidations && !selectedValues.Nighttype && (
                        <span className="form-error-validation">
                          Please select Night Fare!
                        </span>
                      )}
                    </Form.Item>
                  )}
                </div>
              )}
            </Col>
          </Row>

          <Row>
            <Col span={12} className="add-booking-left-section-column-form-col">
              <Form.Item>
                <Input
                  disabled={disableFields}
                  value={selectedValues.alternativeNo}
                  placeholder="Alternative Mobile No"
                  onChange={onAlternateNumberChange}
                  maxLength={10}
                  onKeyDown={handleEnter}
                />
              </Form.Item>
            </Col>
            <Col span={12} className="add-booking-left-section-column-form-col">
              <Form.Item>
                <Input
                  disabled={disableFields}
                  value={selectedValues.refferedby}
                  placeholder="Refered By"
                  onChange={onReferredByChange}
                  onKeyDown={handleEnter}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12} className="add-booking-left-section-column-form-col">
              <Form.Item>
                <Input
                  disabled={disableFields}
                  value={selectedValues.newcompanyName}
                  placeholder="Add Company"
                  onChange={addCompanyChange}
                  onKeyDown={handleEnter}
                />
              </Form.Item>
            </Col>
            <Col span={12} className="add-booking-left-section-column-form-col">
              <Form.Item>
                <Input
                  disabled={disableFields}
                  placeholder="Remarks"
                  value={selectedValues.remarks}
                  onChange={onRemarksChange}
                  onKeyDown={handleEnter}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row style={{ marginLeft: "0.5rem", marginBottom: "1rem" }}>
            <Col span={3}>
              <Checkbox
                checked={cvChecked}
                onChange={() => {
                  setCvChecked(!cvChecked);
                }}
                style={{ color: "#FFFFFF" }}
                onKeyDown={handleEnter}
              >
                {"CV"}
              </Checkbox>
            </Col>
            <Col span={4}>
              <Checkbox
                checked={creditChecked}
                onChange={() => {
                  setCreditChecked(!creditChecked);
                }}
                style={{ color: "#FFFFFF" }}
                onKeyDown={handleEnter}
              >
                {"Prepaid"}
              </Checkbox>
            </Col>
            {/* {Number(selectedValues.tripType)=== 1 && (
            <Col span={3}>
              <Checkbox
                checked={is30Checked}
                onChange={() => {
                  on30perChange(!is30Checked);
                }}
                style={{ color: "#FFFFFF" }}
                onKeyDown={handleEnter}
              >
                {"30%"}
              </Checkbox>
            </Col>)} */}
            <Col span={5}>
              <Checkbox
                checked={tollGateChecked}
                onChange={() => {
                  setTollGateChecked(!tollGateChecked);
                }}
                style={{ color: "#FFFFFF" }}
                onKeyDown={handleEnter}
              >
                {"Toll Gate"}
              </Checkbox>
            </Col>
            <Col span={3}>
              <Checkbox
                checked={gstChecked}
                onChange={() => {
                  ongstChange(!gstChecked);
                }}
                style={{ color: "#FFFFFF" }}
                onKeyDown={handleEnter}
              >
                {"Intra"}
              </Checkbox>
            </Col>

            {/* <Col span={5}>
              <Checkbox
                checked={roundtripChecked}
                onChange={() => {
                  onRoundtripChange(!roundtripChecked);
                }}
                style={{ color: "#FFFFFF" }}
                onKeyDown={handleEnter}
              >
                {"Round Trip"}
              </Checkbox>
            </Col> */}
          </Row>

          <Row className="add-booking-left-section-column-form-btn-row">
            <Col span={4} offset={4}>
              <Button
                htmlType="submit"
                className="add-booking-left-section-column-form-btn-row-booknow"
                onClick={() => {
                  noOfBookings > 1 ? moreBookingConfirmation() : addBooking();
                }}
              >
                {isEditBooking ? "Update Booking" : "Book Now"}
              </Button>
            </Col>
            {!isEditBooking && (
              <>
                {/* <Col span={3} offset={2}>
                  <Button
                    htmlType="submit"
                    className="add-booking-left-section-column-form-btn-row-rac"
                    onClick={addToRac}
                  >
                    RAC
                  </Button>
                </Col> */}
                <Col span={3} offset={1}>
                  <Button
                    htmlType="submit"
                    className="add-booking-left-section-column-form-btn-row-enquiry"
                    onClick={addEnquiry}
                  >
                    Enquiry
                  </Button>
                </Col>
              </>
            )}
          </Row>
        </Form>
      </Spin>
    );
  };

  const renderAvailableCabs = () => {
    const avaiableCabGridColumns = [
      {
        title: "Cab ID",
        dataIndex: "cabid",
        key: "cabid",
        ellipsis: true,
        //render: (text: any) => <a>{text}</a>,
      },
      {
        title: "Vehicle",
        dataIndex: "cabtype",
        key: "cabtype",
        // render: (text: any, record: any) => {
        //   return record.car_Type[0];
        // },
        ellipsis: true,
      },
      {
        title: "Distance",
        dataIndex: "distance",
        key: "distance",
        ellipsis: true,
        //render: (text: any) => <a>{text}</a>,
      },
    ];
    return (
      <Row className="add-booking-left-section-column">
        <Col span={24}>
          <Row className="add-booking-left-section-column-header-row">
            <h3>Available Cab</h3>
          </Row>
          <Row>
            <AppTable
              columns={avaiableCabGridColumns}
              data={availableCabList}
              pagination={false}
            ></AppTable>
          </Row>
        </Col>
      </Row>
    );
  };

  const bookinghistoryGridColumns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      ellipsis: true,
      render: (text: any) => {
        return text ? moment(text).format("DD/MM/YYYY") : null;
      },
    },
    {
      title: "Booking Id",
      dataIndex: "tripid",
      key: "tripid",
      ellipsis: true,
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.status == 2
                  ? "#f5d7c6"
                  : record.status == 1
                  ? "#fce6fc"
                  : record.status == 5
                  ? "#fce6fc"
                  : "#ffffff",
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "M-Truck Id",
      dataIndex: "mtruckid",
      key: "mtruckid",
      ellipsis: true,
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:

                record.status == 2
                  ? "#6aa84f"
                  : record.status == 1
                  ? "#f4cccc"
                  : record.status == 5
                  ? "#df786f"
                  : "#ffffff",
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
  ];

  const renderBookingHistory = () => {
    return (
      <Row className="add-booking-left-section-column">
        <Col span={24}>
          <Row className="add-booking-left-section-column-header-row">
            <h3>Booking History</h3>
          </Row>
          <Row>
            <AppTable
              columns={bookinghistoryGridColumns}
              data={bookingHistoryList}
              pagination={false}
            ></AppTable>
          </Row>
        </Col>
      </Row>
    );
  };
  // const copyrenderBookingHistory = () => {

  //   return (
  //     <Row className="add-booking-right-section-column-each-block">
  //     <Col span={6}>
  //       <Row className="add-booking-left-section-column-header-row">
  //           <h3>Booking History</h3>
  //         </Row>
  //         <Row>
  //           <AppTable
  //             columns={bookinghistoryGridColumns}
  //             data={bookingHistoryList}
  //             pagination={false}
  //           ></AppTable>
  //         </Row>
  //       </Col>
  //     </Row>
  //   );
  // };

  const leftSection = () => {
    return (
      <div>
        <Row className="add-booking-left-section-column-header-row">
          <h3>{isEditBooking ? "Edit Booking" : "Add Booking"}</h3>
        </Row>
        <Row className="add-booking-left-section-column-form">
          <Col span={24}>{renderBookingForm()}</Col>
        </Row>
      </div>
    );
  };

  const rightSection = () => {
    return (
      <>
        <Row className="add-booking-right-section-column-each-block">
          <Col span={24}>
            <Row className="add-booking-right-section-column-header-row">
              <h3>Fare Estimation</h3>
            </Row>
            <Row className="add-booking-right-section-column-fare-info">
              <Col
                span={24}
                offset={4}
                className="add-booking-right-section-column-fare-info-each-row"
              >
                <span
                  style={{
                    fontSize: "large",
                    color: "#82439a",
                    fontWeight: "bold",
                  }}
                >
                  {selectedValues.tripType == 1
                    ? "Meter Fare"
                    : selectedValues.tripType == 2
                    ? "Package Fare"
                    : selectedValues.tripType == 3
                    ? "Intercity Fare"
                    : selectedValues.tripType == 4
                    ? "Night Fare"
                    : ""}{" "}
                </span>
              </Col>{" "}
            </Row>
            <Row>
              <Col
                span={24}
                className="add-booking-right-section-column-fare-info"
              >
                <Row className="add-booking-right-section-column-fare-info-each-row">
                  <Col span={12}>Base Fare :</Col>
                  <Col span={12}>{calculatedMeterFareInfo.basefare || 0}</Col>
                </Row>
                <Row className="add-booking-right-section-column-fare-info-each-row">
                  <Col span={12}>Base KM :</Col>
                  <Col span={12}>
                    {calculatedMeterFareInfo.basekm || 0 + " KM"}
                  </Col>
                </Row>
                <Row className="add-booking-right-section-column-fare-info-each-row">
                  <Col span={12}>Base Min :</Col>
                  <Col span={12}>
                    {calculatedMeterFareInfo.baseminute || 0 + " min"}
                  </Col>
                </Row>
                <Row className="add-booking-right-section-column-fare-info-each-row">
                  <Col span={12}>Base Weight :</Col>
                  <Col span={12}>
                    {selectedValues.vehicleTypeLoadCapicity || 0}{" "}
                  </Col>
                </Row>
                <Row className="add-booking-right-section-column-fare-info-each-row">
                  <Col span={12}>Add KM :</Col>
                  <Col span={12}>
                    {calculatedMeterFareInfo.addkmcharge || 0}
                  </Col>
                </Row>
                <Row className="add-booking-right-section-column-fare-info-each-row">
                  <Col span={12}>Add Min :</Col>
                  <Col span={12}>
                    {calculatedMeterFareInfo.addminutecharge || 0}
                  </Col>
                </Row>
                <Row className="add-booking-right-section-column-fare-info-each-row">
                  <Col span={12}>Approx KM :</Col>
                  <Col span={12} className="approxkm-value">
                    {selectedValues.distance || 0 + " KM"}
                  </Col>
                </Row>
                <Row className="add-booking-right-section-column-fare-info-each-row">
                  <Col span={12}>Additional Fare :</Col>
                  <Col
                    span={12}
                    className={is30Checked ? "approxkm-value" : ""}
                  >
                    {calculatedMeterFareInfo.servicefare || 0}
                  </Col>
                </Row>

                <Row className="add-booking-right-section-column-fare-info-each-row">
                  <Col span={12}>Service Charge :</Col>
                  <Col span={12}>
                    {Math.round(
                      ((Number(calculatedMeterFareInfo.tempapproximatefare) /
                        100) *
                        1.7 +
                        Number.EPSILON) *
                        100
                    ) / 100 || 0}
                  </Col>
                </Row>
                <Row className="add-booking-right-section-column-fare-info-each-row">
                  <Col span={12}>Toll Gate :</Col>
                  <Col
                    span={12}
                    className={tollGateChecked ? "approxkm-value" : ""}
                  >
                    {0}
                  </Col>
                </Row>
                <Row className="add-booking-right-section-column-fare-info-each-row">
                  <Col span={12}>RoundTrip :</Col>
                  <Col
                    span={12}
                    className={roundtripChecked ? "approxkm-value" : ""}
                  >
                    {roundtripChecked ? "Yes" : "No"}
                  </Col>
                </Row>
                <Row className="add-booking-right-section-column-fare-info-fare-summary-row">
                  <Col span={12}>Approx Fare :</Col>
                  <Col span={12} style={{ fontWeight: "bolder", fontSize: 24 }}>
                    {calculatedMeterFareInfo.approximatefare || 0}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  };
  const rightSection2 = () => {
    const bookingHistoryGridColumns = [
      {
        title: "Date",
        dataIndex: "Date",
        key: "Date",
        render: (text: any) => <a>{text}</a>,
      },
      {
        title: "Booking Id",
        dataIndex: "Booking Id",
        key: "Booking Id",
        render: (text: any) => <a>{text}</a>,
      },
    ];
    const renderAvailableCabs = () => {
      const avaiableCabGridColumns = [
        {
          title: "Cab ID",
          dataIndex: "cabid",
          key: "cabid",
          ellipsis: true,
          //render: (text: any) => <a>{text}</a>,
        },
        {
          title: "Vehicle",
          dataIndex: "cabtype",
          key: "cabtype",
          // render: (text: any, record: any) => {
          //   return record.car_Type[0];
          // },
          ellipsis: true,
        },
        {
          title: "Distance",
          dataIndex: "distance",
          key: "distance",
          ellipsis: true,

          //render: (text: any) => <a>{text}</a>,
        },
      ];

      return (
        <Row
          style={{ backgroundColor: "white" }}
          className="add-booking-left-section-column"
        >
          <Col span={24}>
            <Row className="add-booking-left-section-column-header-row">
              <h3>Available Cab</h3>
            </Row>
            <Row>
              <AppTable
                columns={avaiableCabGridColumns}
                data={availableCabList}
                pagination={false}
              ></AppTable>
              <AppModalWithGrid
                title="Available Cabs"
                visible={gridModelVisible}
                hideModal={hideModal}
                width={300}
                isAddbooking={true}
                modalGridColumns={modalReAssignColumns}
                modalGridData={availableCabData}
              />
            </Row>
            <span style={{ color: "blue" }} onClick={showGridModal}>
              More...
            </span>
          </Col>
        </Row>
      );
    };
    return (
      <>
        {renderBookingHistory()}
        {renderAvailableCabs()}
      </>
    );
  };
  return (
    <>
      {/* <Row className="add-booking-right-section-column-each-block">
      <Col span={11} xxl={6} className="add-booking-left-section-column">
        {leftSection()}
      </Col>
      <Col
        // offset={6}
        span={6}
        xxl={6}
        className="add-booking-right-section-column"
      >
        {rightSection()}
      </Col>
      <Col
        // offset={6}
        span={6}
        xxl={3}
        className="add-booking-right-section-column"
      >
        
        {rightSection2()}
      </Col>
      {/* {renderBookingHistory()} */}
      {/* <Col
        // offset={6}
        span={6}
        xxl={3}
        className="add-booking-right-section-column"
      >
        
        {renderAvailableCabs()}
      </Col>  */}

      <Row className="add-booking-right-section-column-each-block">
        <Col span={12} xxl={6} className="add-booking-left-section-column">
          {leftSection()}
        </Col>
        <Col
          span={5}
          xxl={6}
          className="add-booking-right-section-column add-booking-right-section-column-bookinghistorycolumn "
        >
          {rightSection()}
        </Col>
        <Col
          span={7}
          xxl={6}
          className="add-booking-right-section-column add-booking-right-section-column-bookinghistorycolumn"
        >
          {rightSection2()}
        </Col>
      </Row>
    </>
  );
};

export default AddBooking;
