import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Select,
  InputNumber,
  notification,
} from "antd";
import axiosGet, { axiosPost } from "../../helpers/AxiosRequest";
import { UrlConstants } from "../../helpers/UrlConstantsHelper";
import { useHistory } from "react-router-dom";
const { Option } = Select;
const AppModalCancelbeforeAssign = (props: any) => {
  let history: any = useHistory();
  const [visible, setVisible] = useState(false);
  const [selectedRowInfo, setSelectedRowInfo]: any = useState({});
  const [cancelReasonList, setCancelReasonList] = useState([]);
  const [selectedValues, setSelectedValues]: any = useState({});
  const [username, setUsername]: any = useState(
    localStorage.getItem("employeename")
  );

  useEffect(() => {
    setVisible(props.visible);
  }, [props.visible]);

  useEffect(() => {
    if (props.selectedRowData && props.selectedRowData.length > 0) {
      setSelectedRowInfo(props.selectedRowData[0]);
    }
    getCancelReasonList();
  }, [props.selectedRowData]);

  const handleOk = (e: any) => {
    props.hideModal(false);
  };

  const getCancelReasonList = () => {
    if (selectedValues.cancelby == undefined || selectedValues.cancelby == "") {
      selectedValues.cancelby = "2";
      setSelectedValues(selectedValues);
    }
    const options: any = {
      params: {
        canceltype: selectedValues.cancelby,
      },
    };
    axiosGet(UrlConstants.getcancelreason, options)
      .then((resp: any) => {
        selectedValues.reason = undefined;
        setSelectedValues(selectedValues);
        setCancelReasonList(resp);
      })
      .catch(function (error: any) {
        if (error.response) {
          console.log(error.response.data, error.response.status);
        }
      });
  };

  const onreasonChange = (value: any, fullInfo: any) => {
    selectedValues.reason = fullInfo.children;
    setSelectedValues(selectedValues);
  };
  const handleCancel = (e: any) => {
    props.hideModal(false);
  };

  const remarksChange = (e: any) => {
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.remarks = e.target.value;
    setSelectedValues(selectedValuesCopy);
  };

  const oncancelbyChange = (value: any, fullInfo: any) => {
    selectedValues.cancelby = value;
    setSelectedValues(selectedValues);
    getCancelReasonList();
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const onFinish = (values: any) => {
    selectedValues.bookid = selectedRowInfo.id;
    setSelectedValues(selectedValues);
    cancelBooking();
  };

  const cancelBooking = () => {
    const options: any = {
      data: {
        bookid: selectedValues.bookid,
        canceltype: selectedValues.cancelby,
        reason: selectedValues.reason,
        cancelledby: username,
        remarks: selectedValues.remarks,
      },
    };
    axiosPost(UrlConstants.cancelbooking, options)
      .then((resp: any) => {
        const notificationInfo: any = {
          header: "Booking Cancelled Successfully",
          description:
            "Booking Id: " + selectedValues.bookid + " Booking cancelled",
        };
        openNotificationWithIcon("Success", notificationInfo);
        handleOk("close");
      })
      .catch(function (error: any) {
        if (error.response) {
          console.log(error.response.data, error.response.status);
        }
        const notificationInfo = {
          header: "Booking Cancel Failed",
          description: "Error Occurred while Booking Cancel",
        };
        openNotificationWithIcon("Error", notificationInfo);
      });
  };

  const openNotificationWithIcon = (type: any, info: any) => {
    if (type === "Success") {
      notification.success({
        message: info.header,
        description: info.description,
      });
    } else if (type === "Error") {
      notification.error({
        message: info.header,
        description: info.description,
      });
    }
  };

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not validate email!",
      number: "${label} is not a validate number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };
  return (
    <Modal
      destroyOnClose={true}
      className="custom-modal-cancel-form"
      title={props.title}
      width={450}
      visible={visible}
      // onOk={handleOk}
      onCancel={handleCancel}
      footer={[]}
    >
      <Form
        {...layout}
        name="nest-messages"
        validateMessages={validateMessages}
      >
        <Form.Item name={["user", "bookingId"]} label="Booking Id" rules={[]}>
          <Input defaultValue={selectedRowInfo.id} readOnly={true} />
        </Form.Item>
        <Form.Item name={["user", "by"]} label="By">
          <Select
            placeholder="Cancel By"
            value={selectedValues.cancelby}
            defaultValue={selectedValues.cancelby}
            onChange={oncancelbyChange}
          >
            <Option value="2">Customer</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name={["user", "callerReason"]}
          label="Reason"
          rules={[{ required: true }]}
        >
          <Select
            value={selectedValues.reason}
            placeholder="Select Cancel Reason"
            onChange={onreasonChange}
          >
            {cancelReasonList.map((reason: any) => (
              <Option key={reason.id} value={reason.CancelReason}>
                {reason.CancelReason}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name={["user", "remarks"]} label="Remarks" rules={[]}>
          <Input
            type="text"
            value={selectedValues.remarks}
            onChange={remarksChange}
            defaultValue={selectedRowInfo.Remarks}
          />
        </Form.Item>
        <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
          <Button
            className="custom-modal-approve-attendance-approve-btn"
            htmlType="submit"
            onClick={onFinish}
          >
            Submit
          </Button>
          <Button
            className="custom-modal-cancel-form-submit-btn"
            htmlType="button"
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AppModalCancelbeforeAssign;
