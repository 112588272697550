import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Row,
  Col,
  Select,
  Input,
  InputNumber,
  notification,
  Spin,
} from "antd";
import axiosGet, { axiosPost } from "../../helpers/AxiosRequest";
import { UrlConstants } from "../../helpers/UrlConstantsHelper";
import moment from "moment";
const { Option } = Select;
const AppModalWithLogoutDriverAttendance = (props: any) => {
  const [visible, setVisible] = useState(false);
  const [modalData, setModalData]: any = useState({});
  const [logoutdetails, setlogoutdetails]: any = useState({});
  const [username, setUsername]: any = useState(
    localStorage.getItem("employeename")
  );
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (props.selectedRowData.cabid) {
      setModalData(props.selectedRowData);
      getlogoutdetails();
    }
    setVisible(props.visible);
  }, [props.visible, props.selectedRowData]);

  const handleOk = (e: any) => {
    props.hideModal(false);
  };

  const handleCancel = (e: any) => {
    props.hideModal(false);
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const onFinish = (values: any) => {};
  const openNotificationWithIcon = (type: any, info: any) => {
    if (type === "Success") {
      notification.success({
        message: info.header,
        description: info.description,
      });
    } else if (type === "Error") {
      notification.error({
        message: info.header,
        description: info.description,
      });
    }
  };
  const OnClosingKMChange = (e: any) => {
    e = e.target.value.replace(/^0+/, "");
    e = zeroPad(e);
    if (e.length == 7) e = e.slice(0, -1);
    let copyselectedValues = { ...modalData };
    copyselectedValues.closingKM = e;
    setModalData(copyselectedValues);
    let copylogoutdetails = { ...logoutdetails };
    copylogoutdetails.tempclosingkm = e;

    setlogoutdetails(copylogoutdetails);
  };

  function zeroPad(num: any) {
    return num.toString().padStart(6, "0");
  }

  const getlogoutdetails = () => {
    const options = {
      params: {
        cabid: props.selectedRowData.cabid,
      },
    };
    axiosGet(UrlConstants.getlogoutdetails, options)
      .then((resp: any) => {
        resp.forEach((e: any) => {
          let temp = e.closingKM.toString().replace(/^0+/, "");
          temp = zeroPad(temp);
          if (temp.length == 7) temp = temp.slice(0, -1);
          e.tempclosingkm = temp;
        });

        if (resp.length > 0) {
          setlogoutdetails(resp[0]);
          let copyselectedValues = props.selectedRowData;
          if (copyselectedValues.openingKM == undefined)
            copyselectedValues.openingKM = 0;
          let temp = copyselectedValues.openingKM.toString().replace(/^0+/, "");
          temp = zeroPad(temp);
          if (temp.length == 7) temp = temp.slice(0, -1);
          copyselectedValues.openingKM = temp;
          copyselectedValues.closingKM = resp[0].tempclosingkm;
          setModalData(copyselectedValues);
        }
      })
      .catch(function (error: any) {
        setIsLoading(false);
        if (error.response) {
          console.log(error.response.data, error.response.status);
        }
      });
  };
  const approvelogout = () => {
    const options = {
      data: {
        cabid: modalData.cabid,
        closingKM: modalData.closingKM,
        username: username,
      },
    };

    axiosPost(UrlConstants.approvelogout, options)
      .then((resp: any) => {
        const notificationInfo = {
          header:
            "Logout Request for " + modalData.cabid + " has been Approved",
          description: "",
        };
        openNotificationWithIcon("Success", notificationInfo);
        handleCancel(true);
      })
      .catch(function (error: any) {
        if (error.response) {
          console.log(error.response.data, error.response.status);
        }
      });
  };
  return (
    <Spin tip="Loading..." spinning={isLoading}>
      <Modal
        destroyOnClose={true}
        className="custom-modal-approve-attendance"
        title={props.title}
        width={450}
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
      >
        <Row gutter={[20, 20]}>
          <Col span={6} className="custom-modal-approve-attendance-left-column">
            Cab Id:
          </Col>
          <Col span={6}>{modalData.cabid}</Col>
          <Col span={6}>Date: </Col>
          <Col span={6}>
            {moment(modalData.dateandtime).format("DD-MM-YYYY")}
          </Col>
        </Row>
        {/* <Row gutter={[20, 20]}>
        <Col span={12} className="custom-modal-approve-attendance-left-column">
          From Date
        </Col>
        <Col span={12}>02.12.2020</Col>
      </Row> */}
        <Row gutter={[20, 20]}>
          <Col
            span={12}
            className="custom-modal-approve-attendance-left-column"
          >
            Log in time:
          </Col>
          <Col span={7}>
            <Input
              defaultValue={moment(modalData.dateandtime).format("h:mm A")}
              readOnly={true}
            />
          </Col>
        </Row>
        <Row gutter={[20, 20]}>
          <Col
            span={12}
            className="custom-modal-approve-attendance-left-column"
          >
            Starting Km:
          </Col>
          <Col span={7}>
            <Input defaultValue={modalData.openingKM} readOnly={true} />
          </Col>
        </Row>
        <Row gutter={[20, 20]}>
          <Col
            span={12}
            className="custom-modal-approve-attendance-left-column"
          >
            Total Km:
          </Col>
          <Col span={12}>
            <InputNumber defaultValue={logoutdetails.TotalKM} readOnly={true} />
          </Col>
        </Row>
        <Row gutter={[20, 20]}>
          <Col
            span={12}
            className="custom-modal-approve-attendance-left-column"
          >
            Empty Km:
          </Col>
          <Col span={12}>
            <InputNumber defaultValue={logoutdetails.EmptyKM} readOnly={true} />
          </Col>
        </Row>
        <Row gutter={[20, 20]}>
          <Col
            span={12}
            className="custom-modal-approve-attendance-left-column"
          >
            Closing KM:
          </Col>
          <Col span={7}>
            <Input
              maxLength={7}
              defaultValue={logoutdetails.tempclosingkm}
              value={logoutdetails.tempclosingkm}
              onChange={OnClosingKMChange}
            />
          </Col>
        </Row>
        <Row gutter={[20, 20]}>
          <Col
            span={12}
            className="custom-modal-approve-attendance-left-column"
          >
            Amount:
          </Col>
          <Col span={12}>
            <InputNumber defaultValue={logoutdetails.Amount} />
          </Col>
        </Row>
        <Row gutter={[20, 20]}>
          <Col
            span={12}
            className="custom-modal-approve-attendance-left-column"
          >
            Time:
          </Col>
          <Col span={12}>
            <span>{moment().format("h:mm A")}</span>
          </Col>
        </Row>

        <Row gutter={[20, 20]}>
          <Col
            span={12}
            className="custom-modal-approve-attendance-btn-left-column"
          >
            <Button
              className="custom-modal-approve-attendance-approve-btn"
              htmlType="submit"
              onClick={approvelogout}
            >
              Approve
            </Button>
          </Col>
          <Col span={12}>
            <Button
              className="custom-modal-approve-attendance-cancel-btn"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Modal>
    </Spin>
  );
};

export default AppModalWithLogoutDriverAttendance;
