import React, { useState, useEffect } from "react";
import { Tag, Row, Col, Button, notification, Input, Spin } from "antd";
import { EditOutlined } from "@ant-design/icons";
import AppTable from "../../../components/AppTable/AppTable";
import AppModalWithGrid from "../../../components/AppModal/AppModalWithGrid";
import AppModalCancelbeforeAssign from "../../../components/AppModal/AppModalCancelbeforeAssign";
import axiosGet, { axiosPost } from "../../../helpers/AxiosRequest";
import AppModalPreAssign from "../../../components/AppModal/AppModalPreAssign";
import { UrlConstants } from "../../../helpers/UrlConstantsHelper";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";

const { Search } = Input;

const NewBooking = () => {
  let history: any = useHistory();
  const [gridModelVisible, setGridModalVisible] = useState(false);
  const [currentBookingGridData, setCurrentBookingGridData] = useState([]);
  const [copycurrentBookingGridData, setcopyCurrentBookingGridData] = useState(
    []
  );
  const [cancelformModalVisible, setCancelFormModalVisible] = useState(false);
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [selectedRowData, setSelectedRowData]: any = useState([]);
  const [PreAssignmodalVisible, setPreAssignModalVisible] = useState(false);
  const [availableCabData, setAvailableCabData] = useState([]);
  const [branchid, setbranchid]: any = useState(localStorage.getItem("cityid"));
  const [username, setUsername]: any = useState(
    localStorage.getItem("employeename")
  );
  const [Role, setRole]: any = useState(localStorage.getItem("roleid"));
  const [SearchText, setsearchText]: any = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [modalloading, setModalLoading] = useState(false);
  const [noofTrips, setNoofTrips] = useState(0);
  const [TodayTrips, setTodayfTrips] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [pagination, setPagination]: any = useState({
    current: 1,
    pageSize: 100,
  });

  const showpreassignmodal = () => {
    setPreAssignModalVisible(true);
  };
  const hidepreassignmodal = (isVisible: any) => {
    setPreAssignModalVisible(isVisible);
  };
  function earlyBooking(bookedtime: any, calledtime: any) {
    //var diff = Math.abs(currentTime - bookedtime);
    var diff = Math.abs(moment(bookedtime).diff(moment(calledtime)));
    var minutes = Math.floor(diff / 1000 / 60);

    return minutes;
  }
  function lateAssign(bookedtime: any) {
    const currentTime: any = moment();
    var diff = currentTime.diff(bookedtime);
    var minutes = Math.floor(diff / 1000 / 60);
    return minutes;
  }

  const PreAssignColumns = [
    {
      title: "CabId",
      dataIndex: "CabId",
      key: "CabId",
      fixed: "left",
      render: (text: any) => <a>{text}</a>,
    },
    {
      title: "Distance",
      dataIndex: "Distance",
      key: "Distance",
      render: (text: any) => <a>{text}</a>,
    },
    {
      title: "Vehicle",
      dataIndex: "Vehicle",
      key: "Vehicle",
      render: (text: any) => <a>{text}</a>,
    },
    {
      title: "Driver Name",
      dataIndex: "Driver Name",
      key: "Driver Name",
      render: (text: any) => <a>{text}</a>,
    },
    {
      title: "Mobile No",
      dataIndex: "Mobile No",
      key: "Mobile No",
      render: (text: any) => <a>{text?.slice(-10)}</a>,
    },
    {
      title: "Location",
      dataIndex: "Location",
      key: "Location",
      render: (text: any) => <a>{text}</a>,
    },
    {
      title: "Total Trips",
      dataIndex: "Total Trips",
      key: "Total Trips",
      render: (text: any) => <a>{text}</a>,
    },
    {
      title: "Total Amount",
      dataIndex: "Total Amount",
      key: "Total Amount",
      render: (text: any) => <a>{text}</a>,
    },
  ];
  const columns = [
    {
      title: "Book ID",
      dataIndex: "id",
      key: "id",
      fixed: "left",
      //sorter: (a: any, b: any) => a.id - b.id,
      width: "85px",
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.bookedby === "Website" || record.bookedby === "App"
                  ? "#c1f2a2"
                  : record.vehicletype === "Bolero"
                  ? "#f5d7c6"
                  : record.vehicletype === "407"
                  ? "#fce6fc"
                  : record.vehicletype === "EICHER14"
                  ? "#e1f6f7"
                  : "#ffffff",
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      fixed: "left",
      width: "200px",
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                lateAssign(record.bookedtime) > 15
                  ? "#efa09a"
                  : record.vehicletype === "Bolero"
                  ? "#f5d7c6"
                  : record.vehicletype === "407"
                  ? "#fce6fc"
                  : record.vehicletype === "EICHER14"
                  ? "#e1f6f7"
                  : "#ffffff",
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "Mobile",
      dataIndex: "mobileno",
      fixed: "left",
      key: "Mobile",
      ellipsis: true,
      width: "110px",
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                earlyBooking(record.bookedtime, record.calledtime) > 60
                  ? "#85afd6e0"
                  : record.vehicletype === "Bolero"
                  ? "#f5d7c6"
                  : record.vehicletype === "407"
                  ? "#fce6fc"
                  : record.vehicletype === "EICHER14"
                  ? "#e1f6f7"
                  : "#ffffff",
            },
          },
          children: <div>{text?.slice(-10)}</div>,
        };
      },
    },

    {
      title: "Date",
      dataIndex: "bookedtime",
      key: "Date",
      ellipsis: true,
      width: "111px",
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.vehicletype === "Bolero"
                  ? "#f5d7c6"
                  : record.vehicletype === "407"
                  ? "#fce6fc"
                  : record.vehicletype === "EICHER14"
                  ? "#e1f6f7"
                  : "#ffffff",
            },
          },
          children: (
            <div>
              {text !== undefined || text !== ""
                ? moment(text).format("DD/MM/YYYY")
                : ""}
            </div>
          ),
        };
      },
    },
    {
      title: "Time",
      dataIndex: "bookedtime",
      key: "Time",
      ellipsis: true,
      width: "110px",
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.vehicletype === "Bolero"
                  ? "#f5d7c6"
                  : record.vehicletype === "407"
                  ? "#fce6fc"
                  : record.vehicletype === "EICHER14"
                  ? "#e1f6f7"
                  : "#ffffff",
            },
          },
          children: (
            <div>
              {text !== undefined || text !== ""
                ? moment(text).format("hh:mm A")
                : ""}
            </div>
          ),
        };
      },
    },

    {
      title: "From Location",
      dataIndex: "fromloc",
      key: "fromloc",
      //ellipsis: true,
      //sorter: (a: any, b: any) => a.fromloc - b.fromloc,
      // render: (text: any) => <a>{text}</a>,
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.vehicletype === "Bolero"
                  ? "#f5d7c6"
                  : record.vehicletype === "407"
                  ? "#fce6fc"
                  : record.vehicletype === "EICHER14"
                  ? "#e1f6f7"
                  : "#ffffff",
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "To Location",
      dataIndex: "toloc",
      key: "toloc",
      //ellipsis: true,
      // sorter: (a: any, b: any) => a.toloc - b.toloc,
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.vehicletype === "Bolero"
                  ? "#f5d7c6"
                  : record.vehicletype === "407"
                  ? "#fce6fc"
                  : record.vehicletype === "EICHER14"
                  ? "#e1f6f7"
                  : "#ffffff",
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.vehicletype === "Bolero"
                  ? "#f5d7c6"
                  : record.vehicletype === "407"
                  ? "#fce6fc"
                  : record.vehicletype === "EICHER14"
                  ? "#e1f6f7"
                  : "#ffffff",
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "Vehicle",
      dataIndex: "vehicletype",
      key: "vehicletype",
      ellipsis: true,
      width: "85px",
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.vehicletype === "Bolero"
                  ? "#f5d7c6"
                  : record.vehicletype === "407"
                  ? "#fce6fc"
                  : record.vehicletype === "EICHER14"
                  ? "#e1f6f7"
                  : "#ffffff",
            },
          },
          children: (
            <div>{record.iscv ? <Tag color="green">{text}</Tag> : text}</div>
          ),
        };
      },
    },
    {
      title: "Fare Type",
      dataIndex: "faretype",
      key: "faretype",
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.vehicletype === "Bolero"
                  ? "#f5d7c6"
                  : record.vehicletype === "407"
                  ? "#fce6fc"
                  : record.vehicletype === "EICHER14"
                  ? "#e1f6f7"
                  : "#ffffff",
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "Additional Fare",
      dataIndex: "iscredit",
      key: "iscredit",
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.vehicletype === "Bolero"
                  ? "#f5d7c6"
                  : record.vehicletype === "407"
                  ? "#fce6fc"
                  : record.vehicletype === "EICHER14"
                  ? "#e1f6f7"
                  : "#ffffff",
            },
          },
          children: (
            <div
              style={{
                flexDirection: "column",
                display: "flex",
              }}
            >
              {/* <Checkbox defaultChecked={text} disabled /> */}
              {record.isgst && (
                <span
                  style={{
                    color: "red",
                  }}
                >
                  GST
                </span>
              )}
              {record.isadditionalfare && (
                <span
                  style={{
                    color: "red",
                  }}
                >
                  30%
                </span>
              )}
              {record.istollgate && (
                <span
                  style={{
                    color: "red",
                  }}
                >
                  Toll Gate
                </span>
              )}
              {record.iscredit && (
                <span
                  style={{
                    color: "red",
                  }}
                >
                  Credit
                </span>
              )}
            </div>
          ),
        };
      },
      width: "110px",
    },
    {
      title: "Goods",
      dataIndex: "goods",
      key: "goods",
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.vehicletype === "Bolero"
                  ? "#f5d7c6"
                  : record.vehicletype === "407"
                  ? "#fce6fc"
                  : record.vehicletype === "EICHER14"
                  ? "#e1f6f7"
                  : "#ffffff",
            },
          },
          children: <div>{text}</div>,
        };
      },
    },

    {
      title: "Booked by",
      dataIndex: ["bookedby", "updatedby"],
      key: "Booked by",
      ellipsis: true,
      search: false,
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.vehicletype === "Bolero"
                  ? "#f5d7c6"
                  : record.vehicletype === "407"
                  ? "#fce6fc"
                  : record.vehicletype === "EICHER14"
                  ? "#e1f6f7"
                  : "#ffffff",
            },
          },
          children: (
            <div>
              <Row>B - {record.bookedby} </Row>
              {record.updatedby && <Row>U - {record.updatedby} </Row>}
              {record.enquiryby && <Row>E - {record.enquiryby} </Row>}
            </div>
          ),
        };
      },
      width: "110px",
    },
    {
      title: "Alternative",
      dataIndex: "alternativemobileno",
      key: "Alternative",
      ellipsis: true,
      width: "110px",
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.vehicletype === "Bolero"
                  ? "#f5d7c6"
                  : record.vehicletype === "407"
                  ? "#fce6fc"
                  : record.vehicletype === "EICHER14"
                  ? "#e1f6f7"
                  : "#ffffff",
            },
          },
          children: <div>{text?.slice(-10)}</div>,
        };
      },
    },
    {
      title: "Referred by",
      dataIndex: "refferedby",
      key: "reffered",
      ellipsis: true,
      width: "110px",
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.vehicletype === "Bolero"
                  ? "#f5d7c6"
                  : record.vehicletype === "407"
                  ? "#fce6fc"
                  : record.vehicletype === "EICHER14"
                  ? "#e1f6f7"
                  : "#ffffff",
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      ellipsis: true,
      //sorter: (a: any, b: any) => a.cityid - b.cityid,
      width: "120px",
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.vehicletype === "Bolero"
                  ? "#f5d7c6"
                  : record.vehicletype === "407"
                  ? "#fce6fc"
                  : record.vehicletype === "EICHER14"
                  ? "#e1f6f7"
                  : "#ffffff",
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "Edit",
      dataIndex: "city",
      key: "city123",
      ellipsis: true,

      width: "60px",
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.vehicletype === "Bolero"
                  ? "#f5d7c6"
                  : record.vehicletype === "407"
                  ? "#fce6fc"
                  : record.vehicletype === "Eicher"
                  ? "#e1f6f7"
                  : "#ffffff",
            },
          },
          children: (
            <div>
              <Link
                style={{ marginRight: 16 }}
                to={{
                  pathname: "/booking/editBooking",
                  hash: new Date().getTime().toString(),
                  state: { details: record },
                }}
              >
                <EditOutlined className="edit-booking" />
              </Link>
            </div>
          ),
        };
      },
    },
  ];
  const modalReAssignColumns = [
    {
      title: "CabId",
      dataIndex: "cabid",
      key: "cabid",
      fixed: "left",
      render: (text: any, record: any) => (
        <a onClick={() => onAssignBooking(null, record, null)}>{text}</a>
      ),
    },
    {
      title: "Distance",
      dataIndex: "distance",
      key: "distance",
    },
    {
      title: "Vehicle",
      dataIndex: "cabtype",
      key: "cabtype",
    },
    {
      title: "Driver Name",
      dataIndex: "drivername",
      key: "drivername",
      render: (text: any) => <span>{text}</span>,
    },
    {
      title: "Mobile No",
      dataIndex: "drivermobileno",
      key: "drivermobileno",
      render: (text: any) => text?.slice(-10),
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
    },
    {
      title: "Total Trips",
      dataIndex: "totaltrip",
      key: "totaltrip",
    },
    {
      title: "Total Amount",
      dataIndex: "tripfare",
      key: "tripfare",
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background:
                record.tripfare === 0
                  ? "#f5d7c6"
                  : record.tripfare >= 1 && record.tripfare <= 499
                  ? "#f2ee9d"
                  : record.tripfare >= 500 && record.tripfare <= 1000
                  ? "#b0d4a0"
                  : "#fff",
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "Home",
      dataIndex: "startlocation",
      key: "Home",
    },
    {
      title: "Minutes",
      dataIndex: "lastcompletedtime",
      key: "minutes",
      render: (text: any, record: any) => {
        let starttime = moment().startOf("day");
        return Math.round(
          moment.duration(moment().diff(moment(text))).asMinutes()
        );
      },
    },
  ];

  const showGridModal = () => {
    setGridModalVisible(true);
    getAvailableCabList(selectedRowData);
  };

  const hideModal = (isVisible: any) => {
    setGridModalVisible(isVisible);
    getNewBookingList(pagination, SearchText);
  };

  const showCancelFormModal = () => {
    setCancelFormModalVisible(true);
  };

  const hideCancelFormModal = (isVisible: any) => {
    getNewBookingList(pagination, SearchText);
    setCancelFormModalVisible(isVisible);
  };
  const onGridRowSelect = (rowInfo: any) => {
    setIsRowSelected(rowInfo.length > 0);
    setSelectedRowData(rowInfo);
  };

  const onAssignBooking = (rowInfo: any, record: any, rowIndex: any) => {
    let emptyKm = record.distance;
    if (!emptyKm && emptyKm === 0) emptyKm = 1;
    const options = {
      data: {
        cabid: record.cabid,
        bookid: selectedRowData[0].id,
        driverid: record.driverid,
        assignedby: username,
        emptyKM: emptyKm,
      },
    };
    axiosPost(UrlConstants.assignBooking, options)
      .then((resp: any) => {
        hideModal(false);
        getNewBookingList(pagination, SearchText);
        getAvailableCabList(selectedRowData);
        const notificationInfo = {
          header: "Booking is Assigned to " + record.cabid,
          description:
            "You can see all the Live bookings by clicking below button",
          buttonText: "View Live Bookings",
          path: "/currentBooking",
        };
        const notificationInfoCustomer: any = {
          header:
            resp.customersms === false
              ? "Customer SMS sending failed"
              : "Customer SMS Sent Successfully",
          description:
            resp.customersms === false
              ? "Customer SMS sending failed"
              : "Customer SMS Sent Successfully",
        };
        const notificationInfoDriver: any = {
          header:
            resp.driversms === false
              ? "Driver SMS sending failed"
              : "Driver SMS Sent Successfully",
          description:
            resp.driversms === false
              ? "Driver SMS sending failed"
              : "Driver SMS Sent Successfully",
        };
        openNotificationWithIcon("Success", notificationInfo);
        openNotificationWithIcon(
          resp.customersms === false ? "Error" : "Success",
          notificationInfoCustomer
        );
        openNotificationWithIcon(
          resp.driversms === false ? "Error" : "Success",
          notificationInfoDriver
        );
      })
      .catch((error: any) => {
        if (error.response) {
        }
        const notificationInfo = {
          header: "Assigning Booking Failed",
          description: "Error Occurred while Assigning New Booking",
        };
        openNotificationWithIcon("Error", notificationInfo);
      });
  };

  useEffect(() => {
    getNewBookingList(pagination, SearchText);
    getAvailableCabListforpreAssign();
  }, []);

  const getNewBookingList = (pagination: any, SearchText: any) => {
    setIsLoading(true);
    const options: any = {
      params: {
        branchid: branchid,
        limit: pagination.pageSize,
        skip: (pagination.current - 1) * pagination.pageSize,
        filtervalue: SearchText,
      },
    };
    axiosGet(UrlConstants.getNewBooking, options)
      .then((resp: any) => {
        resp.data.forEach((e: any) => {
          e.key = e.id.toString();
        });
        setIsLoading(false);
        setCurrentBookingGridData(resp.data);
        setcopyCurrentBookingGridData(resp.data);
        setTotalCount(resp.tablelength);
        let currentDayBookings = resp.data.filter((value: any) => {
          return moment(value.bookedtime).isSame(moment(), "day");
        });
        tripsCount(currentDayBookings.length);
        setNoofTrips(resp.tablelength);
        setTodayfTrips(resp.todaycount);
        setPagination({
          ...pagination,
          total: resp.tablelength,
        });
      })
      .catch(function (error: any) {
        setIsLoading(false);
        if (error.response) {
        }
      });
  };

  const getAvailableCabList = (rowinfo: any) => {
    setModalLoading(true);
    const options = {
      params: {
        branchid: branchid,
        vehicletype: rowinfo[0].vehicletypeid,
        roleid: Role,
        fromlat: rowinfo[0].fromloclat,
        fromlong: rowinfo[0].fromloclong,
      },
    };

    axiosGet(UrlConstants.getAvailableCabsNew, options).then((resp: any) => {
      setAvailableCabData(resp.data);
      setModalLoading(false);
    });
  };

  const getAvailableCabListforpreAssign = () => {
    const uri = UrlConstants.getAvailableCabsNew + "?branchid=" + branchid;
    axiosGet(uri).then((resp: any) => {
      if (resp !== undefined) {
        resp.forEach((e: any) => {
          e.key = e.cabid;
        });
      }
    });
  };
  const onBookingNavigation = (path: any) => {
    history.push("/booking" + path);
  };

  const openNotificationWithIcon = (type: any, info: any) => {
    const btn = (
      <Button
        type="primary"
        size="small"
        onClick={() => onBookingNavigation(info.path)}
      >
        {info.buttonText}
      </Button>
    );
    if (type === "Success") {
      notification.success({
        message: info.header,
        description: info.description,
        btn,
      });
    } else if (type === "Error") {
      notification.error({
        message: info.header,
        description: info.description,
      });
    }
  };

  const tripsCount = (length: any) => {
    history.push({ newTripscount: length });
  };
  const onPaginationchange = (
    pagination: any,
    filters: any,
    sorter: any,
    extra: any
  ) => {
    setPagination(pagination);
    getNewBookingList(pagination, SearchText);
  };
  /* const onSearch = (value: any) => {
    setsearchText(value);
    getNewBookingList(pagination, value);
  }; */
  const onSearchchange = (e: any) => {
    setsearchText(e.target.value);
    globalSearch(e.target.value);
  };
  const globalSearch = (searchText: any) => {
    let filteredData = currentBookingGridData.filter((value: any) => {
      /*  for(let key in value){
       console.log(key)
      if (value[key] === undefined || value[key] === null){
        value[key] = '';
      }
     } */
      if (value.name === undefined || value.name === null) value.name = "";
      if (value.id === undefined || value.id === null) value.id = "";
      if (value.mobileno === undefined || value.mobileno === null)
        value.mobileno = "";
      if (value.fromloc === undefined || value.fromloc === null)
        value.fromloc = "";
      if (value.toloc === undefined || value.toloc === null) value.toloc = "";
      if (value.bookedby === undefined || value.bookedby === null)
        value.bookedby = "";
      return (
        value.name
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.id.toString().toLowerCase().includes(searchText.toLowerCase()) ||
        value.mobileno
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.fromloc
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.toloc
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.bookedby
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        value.faretype
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase())
      );
    });
    setcopyCurrentBookingGridData(filteredData);
  };

  return (
    <div className="booking-child-routes new-booking">
      <div className="booking-child-routes-button-section">
        <Row>
          {/* <Col span={2}>
            <Button
              className="booking-btn-preassign"
              disabled={!isRowSelected}
              onClick={showpreassignmodal}
            >
              Pre Assign
            </Button>
          </Col> */}
          <Col span={2} offset={1}>
            <Button
              className="booking-btn-assign"
              disabled={
                !isRowSelected ||
                !moment(selectedRowData[0].bookedtime).isSame(moment(), "day")
              }
              onClick={showGridModal}
            >
              Assign
            </Button>
          </Col>

          <Col span={2} offset={1}>
            <Button
              className="booking-btn-cancel"
              disabled={!isRowSelected}
              onClick={showCancelFormModal}
            >
              Cancel
            </Button>
          </Col>
          <Col offset={5} span={1}>
            <span style={{ fontSize: "large", fontWeight: "bold" }}>
              Trips :{" "}
            </span>
          </Col>
          <Col style={{ paddingLeft: "10px" }} span={1}>
            <span style={{ fontSize: "large", fontWeight: "bold" }}>
              {" "}
              {TodayTrips}
            </span>
          </Col>
          <Col span={4} offset={2}>
            {/* <Search placeholder="Search Text" onSearch={onSearch} enterButton /> */}
            <Input
              placeholder="Search Text"
              className="search"
              onChange={onSearchchange}
            />
          </Col>
        </Row>
      </div>

      <div>
        <Spin tip="Loading..." spinning={isLoading}>
          <AppTable
            columns={columns}
            data={copycurrentBookingGridData}
            enableRadioButton={true}
            onRadioButtonChange={onGridRowSelect}
            enablePagination={pagination}
            selectionType="radio"
            scroll={{ x: 2850, y: 480 }}
            onchange={onPaginationchange}
          ></AppTable>
        </Spin>
      </div>
      <AppModalPreAssign
        title="Pre Assign Trip"
        visible={PreAssignmodalVisible}
        hideModal={hidepreassignmodal}
        selectedRowData={selectedRowData}
        width={700}
        modalGridColumns={PreAssignColumns}
        modalGridData={[]}
      />
      <AppModalWithGrid
        title="Assign Trip"
        visible={gridModelVisible}
        hideModal={hideModal}
        isLoading={modalloading}
        width={'fit-content'}
        selectedRowData={selectedRowData}
        modalGridColumns={modalReAssignColumns.filter(
          (item) =>
            moment().isSameOrAfter(moment("18:00:00", "HH:mm:ss")) ||
            item.dataIndex !== "startlocation"
        )}
        isAddbooking={false}
        modalGridData={availableCabData}
        length={availableCabData.length}
        onRowClickEnabled={false}
        onRowClick={onAssignBooking}
        customClass="new-booking-assign"
      />

      <AppModalCancelbeforeAssign
        title="Cancel Booking"
        visible={cancelformModalVisible}
        hideModal={hideCancelFormModal}
        selectedRowData={selectedRowData}
      />
    </div>
  );
};

export default NewBooking;
